var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["merchant:pay:component:product:list"],
              expression: "['merchant:pay:component:product:list']",
            },
          ],
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    inline: "",
                    "label-position": "right",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品编号：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: { placeholder: "请输入商品ID", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.proId,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "proId", $$v)
                          },
                          expression: "tableFrom.proId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.search,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "search", $$v)
                          },
                          expression: "tableFrom.search",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.handleReset },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "名称", prop: "title", "min-width": "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              trigger: "hover",
                              placement: "right",
                              "open-delay": 800,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text_overflow",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(scope.row.title))]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "pup_card" }, [
                              _vm._v(_vm._s(scope.row.title)),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图片", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview line-heightOne" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: JSON.parse(scope.row.headImg)[0],
                                "preview-src-list": JSON.parse(
                                  scope.row.headImg
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "componentProductId",
                  label: "视频号商品ID",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "类目",
                  "min-width": "150",
                  prop: "thirdCatName",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "获得积分",
                  prop: "giveIntegral",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sales", label: "销量", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "库存", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sales", label: "状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("videoStatusFilter")(scope.row.status)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createTime",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 11 &&
                        _vm.checkPermi(["merchant:pay:component:product:puton"])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.upChange(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("上架")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 5 &&
                        _vm.checkPermi([
                          "merchant:pay:component:product:putdown",
                        ])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.downChange(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("下架")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "merchant:pay:component:product:delete",
                                ],
                                expression:
                                  "['merchant:pay:component:product:delete']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": _vm.$constants.page.limit,
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }