"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _permission = require("@/utils/permission");
var _public = require("@/libs/public");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'SeckillList',
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      proName: '',
      activityName: '',
      tableFrom: {
        page: 1,
        limit: this.$constants.page.limit[0],
        proName: '',
        activityName: '',
        auditStatus: '1',
        activityStatus: '',
        proStatus: ''
      },
      headeNum: [{
        name: '待审核',
        type: '1'
      }, {
        name: '审核成功',
        type: '2'
      }, {
        name: '审核失败',
        type: '3'
      }],
      multipleSelection: [],
      dialogVisible: false,
      seckillInfo: {},
      isShow: 0,
      //1审核，2查看，3编辑
      loading: false,
      id: '' //秒杀商品id
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:seckill:product:list'])) this.getList(1);
    this.$store.dispatch('product/getAdminProductClassify');
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //表格选中
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查看编辑
    handleAudit: function handleAudit(row, n) {
      this.id = row.id;
      this.isShow = n;
      this.dialogVisible = true;
      this.seckillInfo = row;
    },
    close: function close(refName) {
      this.dialogVisible = false;
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
    //重新提交
    onEdit: function onEdit(num, item) {
      if (num === 0) {
        this.$cache.local.remove('seckillData');
      } else {
        localStorage.setItem('seckillData', JSON.stringify(item));
      }
      this.$router.push({
        path: '/marketing/seckill/creatSeckill'
      });
    },
    //撤回审核
    handleWithdraw: function handleWithdraw(row) {
      var _this = this;
      this.$modalSure('撤回审核秒杀商品吗？').then(function () {
        (0, _marketing.seckillProWithdrawApi)(row.id).then(function (res) {
          _this.getList();
        });
      });
    },
    handleReset: function handleReset() {
      this.tableFrom.proName = '';
      this.tableFrom.activityName = '';
      this.tableFrom.activityStatus = '';
      this.tableFrom.proStatus = '';
      this.activityName = '';
      this.proName = '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.tableFrom.activityName = encodeURIComponent(this.activityName);
      this.tableFrom.proName = encodeURIComponent(this.proName);
      this.listLoading = true;
      (0, _marketing.seckillProListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    batchDel: function batchDel() {
      var _this3 = this;
      var ids = this.multipleSelection.map(function (item) {
        return item.id;
      }).toString();
      this.$modalSure('批量删除秒杀商品吗？').then(function () {
        (0, _marketing.seckillProDelApi)({
          ids: ids
        }).then(function () {
          _this3.$message.success('删除成功');
          _this3.getList();
        });
      });
    },
    // 删除
    handleDelete: function handleDelete(row) {
      var _this4 = this;
      this.$modalSure('删除该秒杀商品吗？').then(function () {
        (0, _marketing.seckillProDelApi)({
          ids: row.id
        }).then(function () {
          _this4.$message.success('删除成功');
          (0, _public.handleDeleteTable)(_this4.tableData.data.length, _this4.tableFrom);
          _this4.getList();
        });
      });
    },
    batchDown: function batchDown() {
      var _this5 = this;
      var ids = this.multipleSelection.map(function (item) {
        return item.id;
      }).toString();
      this.$modalSure('批量下架秒杀商品吗？').then(function () {
        (0, _marketing.seckillProDownApi)({
          ids: ids
        }).then(function () {
          _this5.$message.success('下架成功');
          _this5.getList();
        });
      });
    },
    handleDown: function handleDown(row, title) {
      var _this6 = this;
      this.$modalSure("".concat(title, "\u8BE5\u79D2\u6740\u5546\u54C1\u5417\uFF1F")).then(function () {
        row.isShow ? (0, _marketing.seckillProDownApi)({
          ids: row.id
        }).then(function () {
          _this6.$message.success("".concat(title, "\u6210\u529F"));
          _this6.getList();
        }) : (0, _marketing.seckillProUpApi)({
          ids: row.id
        }).then(function () {
          _this6.$message.success("".concat(title, "\u6210\u529F"));
          _this6.getList();
        });
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    setPrice: function setPrice(id, row) {
      var _this7 = this;
      var productList = {
        productList: [{
          attrValue: row.map(function (item) {
            return {
              activityPrice: item.price,
              id: item.id
            };
          }),
          id: id
        }]
      };
      (0, _marketing.seckillProSetPriceApi)(productList).then(function (res) {
        _this7.$message.success('添加成功');
        _this7.getList();
        _this7.dialogVisible = false;
      }).catch(function (res) {});
    }
  }
};