var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增物流公司",
        visible: _vm.dialogVisible,
        width: "540px",
        "append-to-body": "",
        "before-close": _vm.handleResetForm,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.dialogVisible
        ? _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "80px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流公司：",
                    required: "",
                    prop: "expressId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formValidate.expressId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "expressId", $$v)
                        },
                        expression: "formValidate.expressId",
                      },
                    },
                    _vm._l(_vm.expressAllList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleResetForm } }, [
            _vm._v("取 消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingBtn },
              on: { click: _vm.handleSure },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }