var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "verifybox-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        width: "340px",
        "append-to-body": true,
        "show-close": true,
        "align-center": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.closeBox,
        title: "请完成安全验证",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          class: _vm.mode == "pop" ? "verifybox" : "",
          style: { "max-width": parseInt(_vm.imgSize.width) + 30 + "px" },
        },
        [
          _c(
            "div",
            { staticClass: "verifybox-bottom" },
            [
              _vm.componentType
                ? _c(_vm.componentType, {
                    ref: "instance",
                    tag: "component",
                    attrs: {
                      "captcha-type": _vm.captchaType,
                      type: _vm.verifyType,
                      figure: _vm.figure,
                      arith: _vm.arith,
                      mode: _vm.mode,
                      "v-space": _vm.vSpace,
                      explain: _vm.explain,
                      "img-size": _vm.imgSize,
                      "block-size": _vm.blockSize,
                      "bar-size": _vm.barSize,
                      "default-img": _vm.defaultImg,
                      phone: _vm.phone,
                    },
                    on: { error: _vm.error, success: _vm.success },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }