"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.throttle = void 0;
var throttle = exports.throttle = {
  bind: function bind(el, binding) {
    var throttleTime = binding.value; // 防抖时间
    if (!throttleTime) {
      // 用户若不设置防抖时间，则默认1s
      throttleTime = 1000;
    }
    var timer;
    var disable = false;
    el.addEventListener('click', function (event) {
      if (timer) {
        clearTimeout(timer);
      }
      if (!disable) {
        // 第一次执行(一点击触发当前事件)
        disable = true;
      } else {
        event && event.stopImmediatePropagation();
      }
      timer = setTimeout(function () {
        timer = null;
        disable = false;
      }, throttleTime);
    }, true);
  }
};