var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          attrs: {
            rules: _vm.rules,
            model: _vm.editPram,
            "label-width": "90px",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "关联用户：", prop: "uid" } }, [
            _c(
              "div",
              {
                staticClass: "upLoadPicBox",
                on: {
                  click: function ($event) {
                    _vm.userVisible = true
                  },
                },
              },
              [
                _vm.editPram.userAvatar
                  ? _c("div", { staticClass: "pictrue" }, [
                      _c("img", { attrs: { src: _vm.editPram.userAvatar } }),
                    ])
                  : _c("div", { staticClass: "upLoad" }, [
                      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "nick" }, [
                  _vm._v(_vm._s(_vm.editPram.nickname)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "from-tips" }, [
                  _vm._v("员工必须在商城关注店铺"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "员工头像：", prop: "avatar" } },
            [
              _c(
                "div",
                {
                  staticClass: "upLoadPicBox",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap("1")
                    },
                  },
                },
                [
                  _vm.editPram.avatar
                    ? _c("div", { staticClass: "pictrue" }, [
                        _c("img", { attrs: { src: _vm.editPram.avatar } }),
                      ])
                    : _c("div", { staticClass: "upLoad" }, [
                        _c("i", {
                          staticClass: "el-icon-camera cameraIconfont",
                        }),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "from-tips" }, [
                    _vm._v(
                      "用于移动端商家管理工作台展示，建议：80*80PX，大小不超过5KB。"
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "员工姓名：", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入员工姓名" },
                model: {
                  value: _vm.editPram.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "name", $$v)
                  },
                  expression: "editPram.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机号：", prop: "phone" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入手机号" },
                model: {
                  value: _vm.editPram.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "phone", $$v)
                  },
                  expression: "editPram.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "管理权限：", prop: "role" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.editPram.role,
                    callback: function ($$v) {
                      _vm.$set(_vm.editPram, "role", $$v)
                    },
                    expression: "editPram.role",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "1" } }, [
                    _vm._v("订单管理"),
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: "2" } }, [
                    _vm._v("商品管理"),
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: "3" } }, [
                    _vm._v("售后管理"),
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: "5" } }, [
                    _vm._v("订单核销"),
                  ]),
                  _vm._v(" "),
                  _c("el-checkbox", { attrs: { label: "6" } }, [
                    _vm._v("销量统计"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "from-tips" }, [
                _vm._v("管理权限控制该员工能够进行的操作限制。"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "status" } },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.editPram.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "status", $$v)
                  },
                  expression: "editPram.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer-inner",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handlerClose()
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("editPram")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联用户列表",
            visible: _vm.userVisible,
            width: "900px",
            "append-to-body": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("userList", { on: { getRow: _vm.getRow } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }