var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": {
              padding: 0,
            },
          },
        },
        [
          _c(
            "div",
            { ref: "tableheader", staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  ref: "userFrom",
                  attrs: { inline: "", size: "small", "label-width": "66px" },
                },
                [
                  _c("div", { staticClass: "acea-row search-form" }, [
                    _c(
                      "div",
                      { staticClass: "search-form-box" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "员工状态：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "form_content_width",
                                attrs: { placeholder: "请选择", clearable: "" },
                                on: { change: _vm.getSearch },
                                model: {
                                  value: _vm.staffSearchData.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.staffSearchData, "status", $$v)
                                  },
                                  expression: "staffSearchData.status",
                                },
                              },
                              _vm._l(_vm.statusOption, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "员工搜索：" } },
                          [
                            _c("el-input", {
                              staticClass: "form_content_width",
                              attrs: {
                                placeholder: "请输入员工姓名或手机号搜索",
                                clearable: "",
                              },
                              model: {
                                value: _vm.staffSearchData.keywords,
                                callback: function ($$v) {
                                  _vm.$set(_vm.staffSearchData, "keywords", $$v)
                                },
                                expression: "staffSearchData.keywords",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "search-form-sub" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.getSearch },
                              },
                              [_vm._v("搜索")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ResetSearch",
                                attrs: { size: "small" },
                                on: { click: _vm.handleReset },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addStaff },
            },
            [_vm._v("添加员工")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticClass: "mt20",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("img", { attrs: { src: row.avatar, alt: "" } }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "员工姓名", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "user", label: "关联用户", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v("uid:"),
                          _c(
                            "a",
                            {
                              staticClass: "uid",
                              on: {
                                click: function ($event) {
                                  return _vm.toDetail(row.uid)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.uid))]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "admin", label: "管理权限", "min-width": "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(_vm._s(_vm.roleText(row.role)) + " ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "creatTime",
                  label: "创建时间",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-switch", {
                          attrs: { "active-value": 1, "inactive-value": 0 },
                          on: {
                            change: function ($event) {
                              return _vm.updataStaff(row)
                            },
                          },
                          model: {
                            value: row.status,
                            callback: function ($$v) {
                              _vm.$set(row, "status", $$v)
                            },
                            expression: "row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleEditMenu(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleDelMenu(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-bottom",
          attrs: {
            title:
              _vm.editDialogConfig.isCreate === 0 ? "添加员工" : "编辑员工",
            visible: _vm.editDialogConfig.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialogConfig, "visible", $event)
            },
          },
        },
        [
          _vm.editDialogConfig.visible
            ? _c("adminEdit", {
                attrs: {
                  "is-create": _vm.editDialogConfig.isCreate,
                  "edit-data": _vm.editDialogConfig.data,
                },
                on: { hideEditDialog: _vm.hideEditDialog },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("detailUser", { ref: "userDetailFrom" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }