var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order_box",
      attrs: {
        visible: _vm.modals,
        title: "发货",
        "before-close": _vm.handleClose,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.modals = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formItem",
          attrs: {
            model: _vm.formItem,
            "label-width": "75px",
            rules: _vm.rules,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { label: "发货类型：", prop: "expressId" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.changeRadio(_vm.formItem.expressRecordType)
                        },
                      },
                      model: {
                        value: _vm.formItem.expressRecordType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formItem, "expressRecordType", $$v)
                        },
                        expression: "formItem.expressRecordType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("普通快递"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递公司：", prop: "expressCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "83%" },
                      attrs: { filterable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.onChangeExport(_vm.formItem.expressCode)
                        },
                      },
                      model: {
                        value: _vm.formItem.expressCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formItem, "expressCode", $$v)
                        },
                        expression: "formItem.expressCode",
                      },
                    },
                    _vm._l(_vm.express, function (item, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { value: item.code, label: item.name },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:express:relate"],
                          expression: "['merchant:express:relate']",
                        },
                      ],
                      staticStyle: { "margin-left": "24px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleCreat },
                    },
                    [_vm._v("设置物流公司")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formItem.expressRecordType === "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "快递单号：", prop: "expressNumber" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入快递单号" },
                        model: {
                          value: _vm.formItem.expressNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formItem,
                              "expressNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formItem.expressNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formItem.expressRecordType === "2"
                ? [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "express_temp_id",
                        attrs: { label: "电子面单：", prop: "expressTempId" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "acea-row" },
                          [
                            _c(
                              "el-select",
                              {
                                class: [
                                  _vm.formItem.expressTempId
                                    ? "width9"
                                    : "width8",
                                ],
                                attrs: { placeholder: "请选择电子面单" },
                                on: { change: _vm.onChangeImg },
                                model: {
                                  value: _vm.formItem.expressTempId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formItem,
                                      "expressTempId",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formItem.expressTempId",
                                },
                              },
                              _vm._l(_vm.exportTempList, function (item, i) {
                                return _c("el-option", {
                                  key: i,
                                  attrs: {
                                    value: item.temp_id,
                                    label: item.title,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _vm.formItem.expressTempId
                              ? _c(
                                  "div",
                                  { staticStyle: { position: "relative" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "tempImgList ml10" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "36px",
                                                height: "36px",
                                              },
                                              attrs: {
                                                src: _vm.tempImg,
                                                "preview-src-list": [
                                                  _vm.tempImg,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "寄件人姓名：", prop: "toName" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请输入寄件人姓名" },
                          model: {
                            value: _vm.formItem.toName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formItem,
                                "toName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formItem.toName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "寄件人电话：", prop: "toTel" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请输入寄件人电话" },
                          model: {
                            value: _vm.formItem.toTel,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formItem,
                                "toTel",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formItem.toTel",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "寄件人地址：", prop: "toAddr" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请输入寄件人地址" },
                          model: {
                            value: _vm.formItem.toAddr,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formItem,
                                "toAddr",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formItem.toAddr",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分单发货：", prop: "isSplit" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": true,
                  "inactive-value": false,
                  "active-text": "开启",
                  "inactive-text": "关闭",
                },
                model: {
                  value: _vm.formItem.isSplit,
                  callback: function ($$v) {
                    _vm.$set(_vm.formItem, "isSplit", $$v)
                  },
                  expression: "formItem.isSplit",
                },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formItem.isSplit,
                      expression: "formItem.isSplit",
                    },
                  ],
                  staticClass: "from-tips",
                },
                [_vm._v("可选择表格中的商品单独发货，请谨慎操作！")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formItem.isSplit
            ? [
                _c(
                  "el-table",
                  {
                    ref: "multipleSelection",
                    staticClass: "tableSelection",
                    staticStyle: { "padding-left": "75px" },
                    attrs: {
                      data: _vm.productList,
                      "tooltip-effect": "dark",
                      size: "small",
                      "row-key": function (row) {
                        return row.id
                      },
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        selectable: _vm.selectable,
                        "reserve-selection": true,
                        "min-width": "50",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "商品信息", width: "200" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "acea-row",
                                    staticStyle: { "align-items": "center" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "demo-image__preview line-heightOne refundImg",
                                      },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: scope.row.image,
                                            "preview-src-list": [
                                              scope.row.image,
                                            ],
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "priceBox product-info-text",
                                            staticStyle: { width: "120px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.productName)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        857809524
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "规格", "min-width": "120" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticClass: "priceBox" }, [
                                  _vm._v(_vm._s(scope.row.sku)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        969154261
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "总数（件）", "min-width": "80" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticClass: "priceBox" }, [
                                  _vm._v(_vm._s(scope.row.payNum)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "priceBox textE93323" },
                                  [
                                    _vm._v(
                                      "已发" + _vm._s(scope.row.deliveryNum)
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "priceBox textE93323" },
                                  [
                                    _vm._v(
                                      "已退款" + _vm._s(scope.row.refundNum)
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        109885883
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "发货数量（件）", "min-width": "120" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  staticClass: "priceBox",
                                  attrs: {
                                    disabled:
                                      scope.row.deliveryNum ===
                                      scope.row.payNum,
                                    min: 1,
                                    max:
                                      Number(scope.row.payNum) -
                                      Number(scope.row.deliveryNum),
                                    step: 1,
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.limitCount(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row["num"],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "num",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "scope.row['num']",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2099503175
                      ),
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.formItem.type === "2"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "送货人姓名：", prop: "deliveryName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入送货人姓名" },
                        model: {
                          value: _vm.formItem.deliveryName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formItem,
                              "deliveryName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formItem.deliveryName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "送货人电话：", prop: "deliveryTel" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入送货人电话" },
                        model: {
                          value: _vm.formItem.deliveryTel,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formItem,
                              "deliveryTel",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formItem.deliveryTel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-btn-top",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.cancel("formItem")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.putSend("formItem")
                },
              },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("creat-express", {
        ref: "craetExpressRef",
        on: { handlerSuccessSubmit: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }