"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  data: function data() {
    return {
      formValidate: {
        type: 0
      },
      productType: [{
        tit: '普通商品',
        id: 0,
        tit2: '实体货物'
      }, {
        tit: '云盘商品',
        id: 5,
        tit2: '统一链接发货'
      }, {
        tit: '卡密商品',
        id: 6,
        tit2: '不同充值码发货'
      }],
      //商品类型
      proTypedialogVisible: false //选择商品类型弹窗
    };
  },
  props: {
    addType: {
      type: String,
      default: function _default() {
        return 'isAdd';
      }
    }
  },
  methods: {
    //选择商品类型确定
    handleSureType: function handleSureType() {
      this.proTypedialogVisible = false;
      var addType = this.addType === 'isAdd' ? 2 : 1;
      //id:商品id，isDisabled：是否能编辑(1不能，2能)，isCopy：是否是采集商品(1是，2不是)
      this.$router.push({
        path: "/product/list/creatProduct/0/2/".concat(addType, "/").concat(this.formValidate.type)
      });
    },
    //选择商品类型
    handleChangeProductType: function handleChangeProductType(id) {
      this.formValidate.type = id;
    }
  }
};