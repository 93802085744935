"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _aside = _interopRequireDefault(require("@/layout/component/aside.vue"));
var _header = _interopRequireDefault(require("@/layout/component/header.vue"));
var _main = _interopRequireDefault(require("@/layout/component/main.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutDefaults',
  components: {
    Asides: _aside.default,
    Headers: _header.default,
    Mains: _main.default
  },
  data: function data() {
    return {};
  },
  computed: {
    // 是否开启固定 header
    isFixedHeader: function isFixedHeader() {
      return this.$store.state.themeConfig.themeConfig.isFixedHeader;
    }
  },
  watch: {
    // 监听路由的变化
    $route: {
      handler: function handler() {
        this.$refs.layoutDefaultsScrollbarRef.wrap.scrollTop = 0;
      },
      deep: true
    }
  }
};