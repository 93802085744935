var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: { shadow: "never", bordered: false },
        },
        [
          !_vm.isDisabled
            ? _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "el-steps",
                    {
                      attrs: {
                        active: _vm.currentTab,
                        "align-center": "",
                        "finish-status": "success",
                      },
                    },
                    [
                      _c("el-step", { attrs: { title: "选择视频号商品" } }),
                      _vm._v(" "),
                      _c("el-step", { attrs: { title: "填写基础信息" } }),
                      _vm._v(" "),
                      _c("el-step", { attrs: { title: "修改商品详情" } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "150px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择商品：", prop: "image" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: [
                                "merchant:pay:component:product:draft:update",
                              ],
                              expression:
                                "['merchant:pay:component:product:draft:update']",
                            },
                          ],
                          staticClass: "upLoadPicBox",
                          on: { click: _vm.changeGood },
                        },
                        [
                          _vm.formValidate.image
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.formValidate.image },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1,
                      expression: "currentTab === 1",
                    },
                  ],
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "商品轮播图：", prop: "images" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  !_vm.isDisabled
                                    ? _vm._l(
                                        _vm.formValidate.images,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "pictrue",
                                              attrs: { draggable: "true" },
                                              on: {
                                                dragstart: function ($event) {
                                                  return _vm.handleDragStart(
                                                    $event,
                                                    item,
                                                    "images"
                                                  )
                                                },
                                                dragover: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleDragOver(
                                                    $event,
                                                    item,
                                                    "images"
                                                  )
                                                },
                                                dragenter: function ($event) {
                                                  return _vm.handleDragEnter(
                                                    $event,
                                                    item,
                                                    "images"
                                                  )
                                                },
                                                dragend: function ($event) {
                                                  return _vm.handleDragEnd(
                                                    $event,
                                                    item,
                                                    "images"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: { src: item },
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-error btndel",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRemove(
                                                      index,
                                                      "images"
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        }
                                      )
                                    : _vm._l(
                                        _vm.formValidate.images,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "pictrue",
                                            },
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "100%",
                                                },
                                                attrs: {
                                                  src: item,
                                                  "preview-src-list": [item],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                  _vm._v(" "),
                                  _vm.formValidate.images.length < 5 &&
                                  !_vm.isDisabled
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap("2")
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品标题：", prop: "title" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  maxlength: "249",
                                  placeholder: "请输入商品名称",
                                },
                                model: {
                                  value: _vm.formValidate.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "title", $$v)
                                  },
                                  expression: "formValidate.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单位：", prop: "unitName" } },
                            [
                              _c("el-input", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  placeholder: "请输入单位",
                                },
                                model: {
                                  value: _vm.formValidate.unitName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "unitName", $$v)
                                  },
                                  expression: "formValidate.unitName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "运费模板：", prop: "tempId" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        disabled: _vm.isDisabled,
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.formValidate.tempId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "tempId",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.tempId",
                                      },
                                    },
                                    _vm._l(_vm.shippingList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "微信商品类目：",
                                prop: "thirdCatIdList",
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "selWidth thirdCatIdList",
                                on: { click: _vm.handleChanges },
                              }),
                              _vm._v(" "),
                              _c("el-cascader", {
                                ref: "demoCascader",
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  filterable: "",
                                  options: _vm.options,
                                  props: _vm.propsCatId,
                                },
                                model: {
                                  value: _vm.formValidate.thirdCatIdList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "thirdCatIdList",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.thirdCatIdList",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品品牌：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formValidate.brandId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "brandId", $$v)
                                    },
                                    expression: "formValidate.brandId",
                                  },
                                },
                                _vm._l(_vm.brandIdList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.productQualificationType > 0
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "商品资质图：" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row" },
                                    [
                                      !_vm.isDisabled
                                        ? _vm._l(
                                            _vm.formValidate
                                              .qualificationPicsList,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "pictrue",
                                                  attrs: { draggable: "true" },
                                                  on: {
                                                    dragstart: function (
                                                      $event
                                                    ) {
                                                      return _vm.handleDragStart(
                                                        $event,
                                                        item,
                                                        "qualificationPics"
                                                      )
                                                    },
                                                    dragover: function (
                                                      $event
                                                    ) {
                                                      $event.preventDefault()
                                                      return _vm.handleDragOver(
                                                        $event,
                                                        item,
                                                        "qualificationPics"
                                                      )
                                                    },
                                                    dragenter: function (
                                                      $event
                                                    ) {
                                                      return _vm.handleDragEnter(
                                                        $event,
                                                        item,
                                                        "qualificationPics"
                                                      )
                                                    },
                                                    dragend: function ($event) {
                                                      return _vm.handleDragEnd(
                                                        $event,
                                                        item,
                                                        "qualificationPics"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: { src: item },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-error btndel",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleRemove(
                                                          index,
                                                          "qualificationPics"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            }
                                          )
                                        : _vm._l(
                                            _vm.formValidate
                                              .qualificationPicsList,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "pictrue",
                                                },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                    },
                                                    attrs: {
                                                      src: item,
                                                      "preview-src-list": [
                                                        item,
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                      _vm._v(" "),
                                      _vm.formValidate.qualificationPicsList
                                        .length < 5 && !_vm.isDisabled
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "upLoadPicBox",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.modalPicTap("3")
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "upLoad" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-camera cameraIconfont",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "textE93323" }, [
                                    _vm._v(
                                      "\n                资质类型说明：" +
                                        _vm._s(_vm.productQualification) +
                                        "。" +
                                        _vm._s(
                                          _vm.productQualificationType === 1
                                            ? "必填项！"
                                            : "选填项！"
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              attrs: { label: "商品属性：", required: "" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "multipleTable",
                                  staticClass: "tableSelection",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    "tooltip-effect": "dark",
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    key: "1",
                                    attrs: { type: "selection", width: "55" },
                                  }),
                                  _vm._v(" "),
                                  _vm.manyTabDate && _vm.formValidate.specType
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { label: "图片", "min-width": "80" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "upLoadPicBox",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.modalPicTap(
                                                      "1",
                                                      "duo",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                scope.row.image
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pictrue tabPic",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: scope.row
                                                              .image,
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "upLoad tabPic",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        "min-width": "140",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm.formThead[iii].title ===
                                                "商品价"
                                                  ? _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        size: "small",
                                                        disabled:
                                                          _vm.isDisabled,
                                                        min: 0,
                                                        precision: 2,
                                                        step: 0.1,
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _c("span", {
                                                      staticClass: "priceBox",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          scope.row[iii]
                                                        ),
                                                      },
                                                    }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2 && !_vm.isDisabled,
                      expression: "currentTab === 2 && !isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品详情：" } },
                    [
                      _c("Tinymce", {
                        model: {
                          value: _vm.formValidate.descInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "descInfo", $$v)
                          },
                          expression: "formValidate.descInfo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2 && _vm.isDisabled,
                      expression: "currentTab === 2 && isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "商品详情：" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formValidate.descInfo || "无"
                            ),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (!_vm.$route.params.id && _vm.currentTab > 0) ||
                            (_vm.$route.params.id && _vm.currentTab === 2),
                          expression:
                            "(!$route.params.id && currentTab > 0) || ($route.params.id && currentTab === 2)",
                        },
                      ],
                      staticClass: "submission priamry_border",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 0,
                          expression: "currentTab === 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest1("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 1,
                          expression: "currentTab === 1",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest2("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.currentTab === 2 || _vm.$route.params.id) &&
                            !_vm.isDisabled,
                          expression:
                            "(currentTab === 2 || $route.params.id) && !isDisabled",
                        },
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: [
                            "merchant:pay:component:product:draft:update",
                            "merchant:pay:component:product:draft:add",
                          ],
                          expression:
                            "['merchant:pay:component:product:draft:update', 'merchant:pay:component:product:draft:add']",
                        },
                      ],
                      staticClass: "submission",
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "modalBox",
          attrs: {
            title: "微信商品类目",
            visible: _vm.dialogVisible,
            width: "900px",
            top: "0vh",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-cascader-panel", {
            ref: "demoCascader",
            staticClass: "modalBox",
            staticStyle: { height: "745px" },
            attrs: {
              filterable: "",
              options: _vm.options,
              props: _vm.propsCatId,
              "popper-append-to-body": false,
              "popper-class": "eloption",
            },
            on: { change: _vm.handleChangesModel },
            model: {
              value: _vm.formValidate.thirdCatIdList,
              callback: function ($$v) {
                _vm.$set(_vm.formValidate, "thirdCatIdList", $$v)
              },
              expression: "formValidate.thirdCatIdList",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }