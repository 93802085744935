"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _list = _interopRequireDefault(require("../user/list"));
var _staff = require("@/api/staff");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userList: _list.default
  },
  props: ['editData', 'isCreate'],
  data: function data() {
    return {
      selectedRow: null,
      userVisible: false,
      editPram: {
        avatar: null,
        name: null,
        uid: null,
        phone: null,
        status: false,
        role: [],
        id: null,
        nickname: null,
        userAvatar: null
      },
      rules: {
        avatar: [{
          required: true,
          message: '请设置头像',
          trigger: 'change'
        }],
        role: [{
          required: true,
          message: '请设置管理权限',
          trigger: 'change'
        }],
        uid: [{
          required: true,
          message: '请关联用户',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          message: '请输入手机号',
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      if (this.isCreate == 1) {
        for (var key in this.editData) {
          this.editPram[key] = this.editData[key];
        }
        this.editPram.role = this.editPram.role.split(',');
      }
    },
    close: function close() {
      this.userVisible = false;
    },
    getUser: function getUser() {
      this.editPram.userAvatar = this.selectedRow.avatar;
      this.editPram.uid = this.selectedRow.id;
      this.editPram.nickname = this.selectedRow.nickname;
      this.userVisible = false;
    },
    getRow: function getRow(row) {
      this.selectedRow = row;
      this.getUser();
    },
    //取消
    handlerClose: function handlerClose() {
      this.$emit('hideEditDialog', 0);
    },
    //表单确认
    handlerSubmit: function handlerSubmit(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (!valid) return;
        var pram = _this2.editPram;
        pram.role = pram.role.join(',');
        pram.status = pram.status ? 1 : 0;
        pram.uid = +pram.uid;
        if (_this2.isCreate == 0) {
          (0, _staff.employeeAddRole)(pram).then(function (res) {
            _this2.$emit('hideEditDialog', 1, 0);
          });
        } else {
          (0, _staff.employeeUpdateRole)(pram).then(function (res) {
            _this2.$emit('hideEditDialog', 1, 1);
          });
        }
      });
    },
    //点击头像
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        if (!img) return;
        if (tit === '1' && !num) {
          _this.editPram.avatar = img[0].sattDir;
        }
        if (tit === '2' && !num) {
          img.map(function (item) {
            attr.push(item.attachment_src);
            _this.formValidate.slider_image.push(item);
          });
        }
      }, tit, 'store');
    }
  }
};