var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.checkPermi(["merchant:mp:live:room:list"])
        ? _c(
            "el-card",
            {
              staticClass: "ivu-mt",
              attrs: {
                bordered: false,
                shadow: "never",
                "body-style": { padding: 0 },
              },
            },
            [
              _c(
                "div",
                { staticClass: "padding-add" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-position": "right",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "显示状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.storeShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "storeShow", $$v)
                                },
                                expression: "tableForm.storeShow",
                              },
                            },
                            _vm._l(_vm.studioShowStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.liveStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "liveStatus", $$v)
                                },
                                expression: "tableForm.liveStatus",
                              },
                            },
                            _vm._l(_vm.studioStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播搜索：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder:
                                "请输入直播间名称/ID/主播昵称/主播微信号/主播副号微信号/主播手机号",
                              size: "small",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.getList(1)
                              },
                            },
                            model: {
                              value: _vm.keywords,
                              callback: function ($$v) {
                                _vm.keywords = $$v
                              },
                              expression: "keywords",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "list-tabs mb5",
              on: {
                "tab-click": function ($event) {
                  return _vm.getList(1)
                },
              },
              model: {
                value: _vm.reviewStatus,
                callback: function ($$v) {
                  _vm.reviewStatus = $$v
                },
                expression: "reviewStatus",
              },
            },
            [
              _c("el-tab-pane", { attrs: { name: " ", label: "全部" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { name: "0", label: "平台待审核" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { name: "1", label: "平台审核失败" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { name: "3", label: "微信审核成功" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { name: "2", label: "微信审核失败" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:mp:live:room:create"],
                  expression: "['merchant:mp:live:room:create']",
                },
              ],
              attrs: { to: { path: "/marketing/broadcast/creatRoom" } },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { size: "small", type: "primary" },
                },
                [_vm._v("添加直播间")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-popover",
            { attrs: { placement: "bottom", width: "200", trigger: "click" } },
            [
              _c("el-image", { attrs: { src: _vm.code } }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", size: "small" },
                  slot: "reference",
                },
                [_vm._v("成为主播")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "40" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "roomName",
                  label: "直播间名称",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "anchorName",
                  label: "主播昵称",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "anchorWechat",
                  label: "主播微信号",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "15",
                attrs: { label: "官方收录", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["merchant:mp:live:room:isfeedspublic"])
                          ? _c("el-switch", {
                              attrs: {
                                disabled: scope.row.reviewStatus === 3,
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsFeeds(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.isFeedsPublic,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isFeedsPublic", $$v)
                                },
                                expression: "scope.row.isFeedsPublic",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.isFeedsPublic === 1
                                    ? "开启"
                                    : "关闭"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "16",
                attrs: { label: "禁言", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["merchant:mp:live:room:updatecomment"])
                          ? _c("el-switch", {
                              attrs: {
                                disabled:
                                  scope.row.liveStatus == 101 &&
                                  scope.row.reviewStatus === 3,
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsCommen(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.closeComment,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "closeComment", $$v)
                                },
                                expression: "scope.row.closeComment",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.closeComment === 1 ? "开启" : "关闭"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "17",
                attrs: { label: "客服开关", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["merchant:mp:live:room:closekf"])
                          ? _c("el-switch", {
                              attrs: {
                                disabled:
                                  scope.row.liveStatus == 101 &&
                                  scope.row.reviewStatus === 3,
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsKf(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.closeKf,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "closeKf", $$v)
                                },
                                expression: "scope.row.closeKf",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.closeKf === 1 ? "开启" : "关闭"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "18",
                attrs: { label: "回放", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["merchant:mp:live:room:updatereplay"])
                          ? _c("el-switch", {
                              attrs: {
                                disabled: scope.row.liveStatus !== 101,
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeCloseReplay(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.liveReplay,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "liveReplay", $$v)
                                },
                                expression: "scope.row.liveReplay",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.liveReplay === 1 ? "开启" : "关闭"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商城显示", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeShow === 1
                          ? _c("span", [_vm._v("显示")])
                          : _c("span", [_vm._v("关闭")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "直播状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("broadcastStatusFilter")(
                                scope.row.liveStatus
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", "min-width": "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reviewStatus === 1 ||
                        scope.row.reviewStatus === 2
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: scope.row.reviewReason,
                                  placement: "top",
                                },
                              },
                              [
                                scope.row.reviewStatus === 1
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass:
                                          "notStartTag tag-background",
                                      },
                                      [_vm._v("平台审核失败")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 2
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass:
                                          "notStartTag tag-background",
                                      },
                                      [_vm._v("微信审核失败")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                scope.row.reviewStatus === 0
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass: "doingTag tag-background",
                                      },
                                      [_vm._v("待审核")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 3
                                  ? _c(
                                      "el-tag",
                                      { staticClass: "endTag tag-background" },
                                      [_vm._v("微信审核成功")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "startTime",
                  "min-width": "150",
                  label: "直播开始时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "endTime",
                  "min-width": "150",
                  label: "直播计划结束时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:mp:live:room:info"],
                                expression: "['merchant:mp:live:room:info']",
                              },
                            ],
                            attrs: {
                              to: {
                                path:
                                  "/marketing/broadcast/creatRoom/" +
                                  scope.row.id +
                                  "/detail",
                              },
                            },
                          },
                          [
                            _c("a", { attrs: { size: "small" } }, [
                              _vm._v("详情"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        Number(scope.row.liveStatus) < 103 &&
                        scope.row.reviewStatus === 3 &&
                        scope.row.closeGoods === 1 &&
                        _vm.checkPermi(["merchant:mp:live:room:addgoods"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleImport(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("导入商品")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.reviewStatus === 3 &&
                        _vm.checkPermi([
                          "merchant:mp:live:room:mangerass",
                          "merchant:mp:live:room:getass",
                        ])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEditAssistant(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑助手")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.liveStatus === 102 &&
                        scope.row.reviewStatus === 1 &&
                        scope.row.liveStatus === "" &&
                        _vm.checkPermi(["merchant:mp:live:room:edit"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      path:
                                        "/marketing/broadcast/creatRoom/" +
                                        scope.row.id,
                                    },
                                  },
                                },
                                [_c("a", [_vm._v("编辑")])]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:mp:live:room:delete"],
                                expression: "['merchant:mp:live:room:delete']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": _vm.$constants.page.limit,
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "商品信息",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "box-container" }, [
                    _c(
                      "div",
                      { staticClass: "list" },
                      [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("小助手："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "selectLoadMore",
                                rawName: "v-selectLoadMore",
                                value: _vm.selectLoadMore,
                                expression: "selectLoadMore",
                              },
                            ],
                            staticClass: "selWidth",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              loading: _vm.loading,
                              remote: "",
                              multiple: "",
                              "remote-method": _vm.remoteMethod,
                              placeholder: "请选择小助手",
                            },
                            model: {
                              value: _vm.users,
                              callback: function ($$v) {
                                _vm.users = $$v
                              },
                              expression: "users",
                            },
                          },
                          _vm._l(_vm.assistantList, function (user) {
                            return _c("el-option", {
                              key: user.id,
                              attrs: {
                                label: user.wechatNickname + "/" + user.wechat,
                                value: user.id,
                              },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "80px" },
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleAssistant },
                          },
                          [_vm._v("确定")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品信息",
            visible: _vm.importVisible,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.importVisible = $event
            },
          },
        },
        [
          _vm.importVisible
            ? _c("import-goods", {
                ref: "uploadGoods",
                on: { getGoodList: _vm.getGoodList },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "直播间分享二维码",
            visible: _vm.sharecodeVisible,
            width: "1000px",
            "before-close": _vm.handleClose("sharecodeVisible"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.sharecodeVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "demo-image__preview",
            },
            [
              _c("el-image", {
                staticStyle: { width: "100px", height: "100px" },
                attrs: {
                  src: _vm.sharecode,
                  "preview-src-list": [_vm.sharecode],
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }