var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _vm.checkPermi(["merchant:order:page:list"])
        ? _c(
            "el-card",
            {
              staticClass: "ivu-mt",
              attrs: {
                bordered: false,
                shadow: "never",
                "body-style": { padding: 0 },
              },
            },
            [
              _c(
                "div",
                { staticClass: "padding-add" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { inline: "", "label-position": "right" },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单类型：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form_content_width",
                              attrs: {
                                clearable: "",
                                size: "small",
                                placeholder: "请选择",
                              },
                              on: { change: _vm.handleSearchList },
                              model: {
                                value: _vm.tableFrom.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "type", $$v)
                                },
                                expression: "tableFrom.type",
                              },
                            },
                            _vm._l(_vm.fromType, function (item, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: item.text, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "时间选择：" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "form_content_width",
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "订单编号：", "label-width": "66px" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_content_width",
                            attrs: {
                              placeholder: "请输入订单号",
                              size: "small",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleSearchList($event)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.orderNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableFrom,
                                  "orderNo",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "tableFrom.orderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.handleSearchList },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.checkPermi(["merchant:order:status:num", "merchant:order:page:list"])
        ? _c(
            "el-card",
            {
              staticClass: "box-card mt16",
              attrs: {
                shadow: "never",
                bordered: false,
                "body-style": { padding: "0 20px 20px" },
              },
            },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "list-tabs",
                  on: { "tab-click": _vm.handleSearchList },
                  model: {
                    value: _vm.tableFrom.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "status", $$v)
                    },
                    expression: "tableFrom.status",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: {
                      name: "all",
                      label: "全部(" + (_vm.orderChartType.all || 0) + ")",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: {
                      name: "notShipped",
                      label:
                        "待发货(" + (_vm.orderChartType.notShipped || 0) + ")",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: {
                      name: "spike",
                      label: "待收货(" + (_vm.orderChartType.spike || 0) + ")",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: {
                      name: "awaitVerification",
                      label:
                        "待核销(" +
                        (_vm.orderChartType.verification || 0) +
                        ")",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: {
                      name: "complete",
                      label:
                        "已完成(" + (_vm.orderChartType.complete || 0) + ")",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: {
                      name: "refunded",
                      label:
                        "已退款(" + (_vm.orderChartType.refunded || 0) + ")",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: {
                      name: "deleted",
                      label:
                        "已删除(" + (_vm.orderChartType.deleted || 0) + ")",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt5" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:order:verification"],
                          expression: "['merchant:order:verification']",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onWriteOff },
                    },
                    [_vm._v("核销订单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:export:order:excel"],
                          expression: "['merchant:export:order:excel']",
                        },
                      ],
                      attrs: { size: "small" },
                      on: { click: _vm.exports },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "mt20",
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "highlight-current-row": "",
                    "row-key": function (row) {
                      return row.orderNo
                    },
                  },
                },
                [
                  _vm.checkedCities.includes("订单号")
                    ? _c("el-table-column", {
                        attrs: { label: "订单号", "min-width": "220" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row" },
                                    [
                                      _c(
                                        "font",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: scope.row.type === 1,
                                              expression:
                                                "scope.row.type === 1",
                                            },
                                          ],
                                          staticClass: "mr5",
                                        },
                                        [_vm._v("[视频号]")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "font",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: scope.row.type === 2,
                                              expression:
                                                "scope.row.type === 2",
                                            },
                                          ],
                                          staticClass: "mr5",
                                        },
                                        [_vm._v("[秒杀]")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "font",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: scope.row.type === 5,
                                              expression:
                                                "scope.row.type === 5",
                                            },
                                          ],
                                          staticClass: "mr5",
                                        },
                                        [_vm._v("[云盘]")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "font",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: scope.row.type === 6,
                                              expression:
                                                "scope.row.type === 6",
                                            },
                                          ],
                                          staticClass: "mr5",
                                        },
                                        [_vm._v("[卡密]")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", {
                                        class:
                                          parseInt(scope.row.refundStatus) > 0
                                            ? "colorPrompt"
                                            : "",
                                        staticStyle: { display: "block" },
                                        domProps: {
                                          textContent: _vm._s(
                                            scope.row.orderNo
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            parseInt(scope.row.refundStatus) >
                                            0,
                                          expression:
                                            "parseInt(scope.row.refundStatus) > 0",
                                        },
                                      ],
                                      staticClass: "colorPrompt",
                                      staticStyle: { display: "block" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("orderRefundStatusFilter")(
                                            scope.row.refundStatus
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.isUserDel,
                                          expression: "scope.row.isUserDel",
                                        },
                                      ],
                                      staticClass: "colorPrompt",
                                      staticStyle: { display: "block" },
                                    },
                                    [_vm._v("用户已删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2597192419
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("用户昵称")
                    ? _c("el-table-column", {
                        attrs: { label: "用户昵称", "min-width": "150" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        scope.row.isLogoff == true
                                          ? "colorPrompt"
                                          : "",
                                    },
                                    [_vm._v(_vm._s(scope.row.nickName))]
                                  ),
                                  _vm._v(" "),
                                  scope.row.isLogoff == true
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            scope.row.isLogoff == true
                                              ? "colorPrompt"
                                              : "",
                                        },
                                        [_vm._v("|")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.isLogoff == true
                                    ? _c(
                                        "span",
                                        { staticClass: "colorPrompt" },
                                        [_vm._v("(已注销)")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2075866614
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("实际支付")
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "payPrice",
                          label: "实际支付",
                          "min-width": "80",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("支付方式")
                    ? _c("el-table-column", {
                        attrs: { label: "支付方式", "min-width": "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("payTypeFilter")(
                                          scope.row.payType
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2281245087
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedCities.includes("订单状态")
                    ? _c("el-table-column", {
                        attrs: { label: "订单状态", "min-width": "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.refundStatus === 3
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "textE93323 tag-background notStartTag tag-padding",
                                        },
                                        [_vm._v("已退款")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "tag-background tag-padding",
                                          class:
                                            scope.row.status < 5
                                              ? "doingTag"
                                              : "endTag",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("orderStatusFilter")(
                                                scope.row.status
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          613573117
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "备注", "min-width": "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(scope.row.merRemark)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1592836402
                    ),
                  }),
                  _vm._v(" "),
                  _vm.checkedCities.includes("下单时间")
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "下单时间",
                          "min-width": "150",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: { width: "180", fixed: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.checkPermi(["merchant:order:info"])
                                  ? _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.onOrderDetails(
                                              scope.row.orderNo
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("详情 ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("el-divider", {
                                  attrs: { direction: "vertical" },
                                }),
                                _vm._v(" "),
                                (scope.row.status === 1 ||
                                  scope.row.status === 2) &&
                                parseFloat(scope.row.refundStatus) < 3 &&
                                _vm.checkPermi(["merchant:order:send"])
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.sendOrder(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("发货 ")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown",
                                  { attrs: { trigger: "click" } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [
                                        _vm._v(" 更多"),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        !scope.row.userRefundSign &&
                                        _vm.checkPermi([
                                          "merchant:order:direct:refund",
                                        ])
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.onDirectRefund(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "直接退款\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        (parseFloat(scope.row.status) < 7 ||
                                          scope.row.refundStatus == 3) &&
                                        _vm.merPrintStatus !== 2 &&
                                        _vm.checkPermi(["merchant:order:print"])
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.handlePrintReceipt(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "打印小票\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.checkPermi(["merchant:order:mark"])
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.onOrderMark(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "订单备注\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.isUserDel === 1 &&
                                        _vm.checkPermi([
                                          "merchant:order:delete",
                                        ])
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "删除订单\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3399660180
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("p", [
                          _c(
                            "span",
                            { staticStyle: { "padding-right": "5px" } },
                            [_vm._v("操作")]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "el-icon-setting",
                            on: { click: _vm.handleAddItem },
                          }),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-sizes": _vm.$constants.page.limit,
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.card_select_show,
                      expression: "card_select_show",
                    },
                  ],
                  staticClass: "card_abs",
                },
                [
                  [
                    _c(
                      "div",
                      { staticClass: "cell_ht" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { indeterminate: _vm.isIndeterminate },
                            on: { change: _vm.handleCheckAllChange },
                            model: {
                              value: _vm.checkAll,
                              callback: function ($$v) {
                                _vm.checkAll = $$v
                              },
                              expression: "checkAll",
                            },
                          },
                          [_vm._v("全选\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.checkSave()
                              },
                            },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.handleCheckedCitiesChange },
                        model: {
                          value: _vm.checkedCities,
                          callback: function ($$v) {
                            _vm.checkedCities = $$v
                          },
                          expression: "checkedCities",
                        },
                      },
                      _vm._l(_vm.columnData, function (item) {
                        return _c(
                          "el-checkbox",
                          {
                            key: item,
                            staticClass: "check_cell",
                            attrs: { label: item },
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.dialogVisibleJI,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleJI = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "oid", label: "ID", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "changeMessage",
                  label: "操作记录",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作时间",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": _vm.$constants.page.limit,
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { orderNo: _vm.orderNo },
      }),
      _vm._v(" "),
      _c("order-send", {
        ref: "send",
        attrs: { orderNo: _vm.orderNo },
        on: { submitFail: _vm.handleSearchList },
      }),
      _vm._v(" "),
      _vm.dialogVisibleDirectRefund
        ? _c("direct-refund", {
            attrs: {
              dialogVisibleDirectRefund: _vm.dialogVisibleDirectRefund,
              orderNo: _vm.orderNo,
            },
            on: {
              handlerSuccessClose: _vm.handlerSuccessClose,
              handlerSuccessSubmit: _vm.handlerSuccessSubmit,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }