"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _verifySlider = _interopRequireDefault(require("./Verify/verifySlider.vue"));
var _verifyPoints = _interopRequireDefault(require("./Verify/verifyPoints.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/**
 * Verify 验证码组件
 * @description 分发验证码使用
 * */
var _default2 = exports.default = {
  name: 'Vue2Verify',
  components: {
    VerifySlide: _verifySlider.default,
    VerifyPoints: _verifyPoints.default
  },
  props: {
    captchaType: {
      type: String,
      default: 'clickWord'
    },
    phone: {
      type: String,
      default: ''
    },
    figure: {
      type: Number
    },
    arith: {
      type: Number
    },
    mode: {
      type: String,
      default: 'pop'
    },
    vSpace: {
      type: Number
    },
    explain: {
      type: String
    },
    imgSize: {
      type: Object,
      default: function _default() {
        return {
          width: '310px',
          height: '155px'
        };
      }
    },
    blockSize: {
      type: Object
    },
    barSize: {
      type: Object
    }
  },
  data: function data() {
    return {
      showBox: false,
      clickShow: false,
      // 内部类型
      verifyType: undefined,
      // 所用组件类型
      componentType: undefined,
      // 默认图片
      defaultImg: '',
      dialogVisible: false
    };
  },
  computed: {
    instance: function instance() {
      return this.$refs.instance || {};
    }
  },
  watch: {
    captchaType: {
      immediate: true,
      handler: function handler(captchaType) {
        switch (captchaType.toString()) {
          case 'blockPuzzle':
            this.verifyType = '2';
            this.componentType = 'VerifySlide';
            break;
          case 'clickWord':
            this.verifyType = '';
            this.componentType = 'VerifyPoints';
            break;
        }
      }
    }
  },
  mounted: function mounted() {
    this.uuid();
  },
  methods: {
    // 生成 uuid
    uuid: function uuid() {
      var s = [];
      var hexDigits = '0123456789abcdef';
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '-';
      var slider = 'slider' + '-' + s.join('');
      var point = 'point' + '-' + s.join('');
      // 判断下是否存在 slider
      if (!localStorage.getItem('slider')) {
        localStorage.setItem('slider', slider);
      }
      if (!localStorage.getItem('point')) {
        localStorage.setItem('point', point);
      }
    },
    /**
     * refresh
     * @description 刷新
     * */
    refresh: function refresh() {
      if (this.instance.refresh) {
        this.instance.refresh();
      }
    },
    closeBox: function closeBox() {
      this.clickShow = false;
      this.dialogVisible = false;
      this.refresh();
    },
    show: function show() {
      this.dialogVisible = true;
      if (this.mode == 'pop') {
        this.clickShow = true;
      }
    },
    error: function error() {
      // this.closeBox()
    },
    success: function success(e) {
      this.closeBox();
      this.$emit('success', e);
    }
  }
};