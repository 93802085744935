"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/navBars/breadcrumb/index.vue"));
var _tagsView = _interopRequireDefault(require("@/layout/navBars/tagsView/tagsView.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutNavBars',
  components: {
    BreadcrumbIndex: _index.default,
    TagsView: _tagsView.default
  },
  data: function data() {
    return {};
  },
  computed: {
    // 设置是否显示 tagsView
    setShowTagsView: function setShowTagsView() {
      var _this$$store$state$th = this.$store.state.themeConfig.themeConfig,
        layout = _this$$store$state$th.layout,
        isTagsview = _this$$store$state$th.isTagsview;
      return layout !== 'classic' && isTagsview;
    }
  }
};