"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _util = require("./../utils/util");
var _ase = require("./../utils/ase");
var _systemFormConfig = require("@/api/systemFormConfig");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/**
 * VerifyPoints
 * @description 点选
 * */
var _default2 = exports.default = {
  name: 'VerifyPoints',
  props: {
    // 弹出式pop，固定fixed
    mode: {
      type: String,
      default: 'fixed'
    },
    captchaType: {
      type: String
    },
    // 间隔
    vSpace: {
      type: Number,
      default: 5
    },
    imgSize: {
      type: Object,
      default: function _default() {
        return {
          width: '310px',
          height: '155px'
        };
      }
    },
    barSize: {
      type: Object,
      default: function _default() {
        return {
          width: '310px',
          height: '40px'
        };
      }
    },
    defaultImg: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      secretKey: '',
      // 后端返回的ase加密秘钥
      checkNum: 3,
      // 默认需要点击的字数
      fontPos: [],
      // 选中的坐标信息
      checkPosArr: [],
      // 用户点击的坐标
      num: 1,
      // 点击的记数
      pointBackImgBase: '',
      // 后端获取到的背景图片
      poinTextList: [],
      // 后端返回的点击字体顺序
      backToken: '',
      // 后端返回的token值
      setSize: {
        imgHeight: 0,
        imgWidth: 0,
        barHeight: 0,
        barWidth: 0
      },
      tempPoints: [],
      text: '',
      barAreaColor: undefined,
      barAreaBorderColor: undefined,
      showRefresh: true,
      bindingClick: true
    };
  },
  computed: {
    resetSize: function resetSize() {
      return _util.resetSize;
    }
  },
  watch: {
    // type变化则全面刷新
    type: {
      immediate: true,
      handler: function handler() {
        this.init();
      }
    }
  },
  mounted: function mounted() {
    // 禁止拖拽
    this.$el.onselectstart = function () {
      return false;
    };
  },
  methods: {
    init: function init() {
      var _this = this;
      // 加载页面
      this.fontPos.splice(0, this.fontPos.length);
      this.checkPosArr.splice(0, this.checkPosArr.length);
      this.num = 1;
      this.getPictrue();
      this.$nextTick(function () {
        _this.setSize = _this.resetSize(_this); // 重新设置宽度高度
        _this.$parent.$emit('ready', _this);
      });
    },
    canvasClick: function canvasClick(e) {
      var _this2 = this;
      this.checkPosArr.push(this.getMousePos(this.$refs.canvas, e));
      if (this.num == this.checkNum) {
        this.num = this.createPoint(this.getMousePos(this.$refs.canvas, e));
        // 按比例转换坐标值
        this.checkPosArr = this.pointTransfrom(this.checkPosArr, this.setSize);
        // 等创建坐标执行完
        setTimeout(function () {
          // var flag = this.comparePos(this.fontPos, this.checkPosArr);
          // 发送后端请求
          var captchaVerification = _this2.secretKey ? (0, _ase.aesEncrypt)(_this2.backToken + '---' + JSON.stringify(_this2.checkPosArr), _this2.secretKey) : _this2.backToken + '---' + JSON.stringify(_this2.checkPosArr);
          var data = {
            captchaType: _this2.captchaType,
            pointJson: _this2.secretKey ? (0, _ase.aesEncrypt)(JSON.stringify(_this2.checkPosArr), _this2.secretKey) : JSON.stringify(_this2.checkPosArr),
            token: _this2.backToken
          };
          (0, _systemFormConfig.knowUserSmsCaptchaApi)(data).then(function (res) {
            if (res.repCode == '0000') {
              _this2.barAreaColor = '#4cae4c';
              _this2.barAreaBorderColor = '#5cb85c';
              _this2.text = '验证成功';
              _this2.bindingClick = false;
              if (_this2.mode == 'pop') {
                setTimeout(function () {
                  _this2.$parent.clickShow = false;
                  _this2.refresh();
                }, 1500);
              }
              setTimeout(function () {
                _this2.tipWords = '';
                _this2.$emit('success', {
                  captchaVerification: captchaVerification
                });
              }, 1000);

              // this.$parent.$emit('success', { captchaVerification });
            } else {
              _this2.$parent.$emit('error', _this2);
              _this2.barAreaColor = '#d9534f';
              _this2.barAreaBorderColor = '#d9534f';
              _this2.text = '验证失败';
              setTimeout(function () {
                _this2.refresh();
              }, 700);
            }
          });
        }, 400);
      }
      if (this.num < this.checkNum) {
        this.num = this.createPoint(this.getMousePos(this.$refs.canvas, e));
      }
    },
    // 获取坐标
    getMousePos: function getMousePos(obj, e) {
      var x = e.offsetX;
      var y = e.offsetY;
      return {
        x: x,
        y: y
      };
    },
    // 创建坐标点
    createPoint: function createPoint(pos) {
      this.tempPoints.push(Object.assign({}, pos));
      return ++this.num;
    },
    refresh: function refresh() {
      this.tempPoints.splice(0, this.tempPoints.length);
      this.barAreaColor = '#000';
      this.barAreaBorderColor = '#ddd';
      this.bindingClick = true;
      this.fontPos.splice(0, this.fontPos.length);
      this.checkPosArr.splice(0, this.checkPosArr.length);
      this.num = 1;
      this.getPictrue();
      this.text = '验证失败';
      this.showRefresh = true;
    },
    // 请求背景图片和验证图片
    getPictrue: function getPictrue() {
      var _this3 = this;
      var data = {
        captchaType: this.captchaType,
        clientUid: localStorage.getItem('point'),
        ts: Date.now() // 现在的时间戳
      };
      (0, _systemFormConfig.knowUserCaptchaApi)(data).then(function (res) {
        if (res.repCode == '0000') {
          _this3.pointBackImgBase = res.repData.originalImageBase64;
          _this3.backToken = res.repData.token;
          _this3.secretKey = res.repData.secretKey;
          _this3.poinTextList = res.repData.wordList;
          _this3.text = '请依次点击【' + _this3.poinTextList.join(',') + '】';
        } else {
          _this3.text = res.repMsg;
        }

        // 判断接口请求次数是否失效
        if (res.repCode == '6201') {
          _this3.pointBackImgBase = null;
        }
      });
    },
    // 坐标转换函数
    pointTransfrom: function pointTransfrom(pointArr, imgSize) {
      var newPointArr = pointArr.map(function (p) {
        var x = Math.round(310 * p.x / parseInt(imgSize.imgWidth));
        var y = Math.round(155 * p.y / parseInt(imgSize.imgHeight));
        return {
          x: x,
          y: y
        };
      });
      return newPointArr;
    }
  }
};