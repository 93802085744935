"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/navBars/index.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutHeader',
  components: {
    NavBarsIndex: _index.default
  },
  data: function data() {
    return {};
  },
  computed: {
    // 设置顶部 header 的具体高度
    setHeaderHeight: function setHeaderHeight() {
      var _this$$store$state$th = this.$store.state.themeConfig.themeConfig,
        isTagsview = _this$$store$state$th.isTagsview,
        layout = _this$$store$state$th.layout;
      if (isTagsview && layout !== 'classic') return '84px';else return '50px';
    }
  }
};