"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = require("@/utils/permission");
var _productCdkey = require("@/api/productCdkey");
var _addCdkey = _interopRequireDefault(require("@/views/product/components/addCdkey.vue"));
var _editCdkey = _interopRequireDefault(require("@/views/product/components/editCdkey.vue"));
var _settings = _interopRequireDefault(require("@/settings"));
var _auth = require("@/utils/auth");
var _systemSetting = require("@/api/systemSetting");
var _ZBKJIutil = require("@/utils/ZBKJIutil");
var _vuex = require("vuex");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'creatCdkey',
  components: {
    addCdkey: _addCdkey.default,
    EditCdkey: _editCdkey.default
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['mediaDomain'])),
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: this.$constants.page.limit[0],
        libraryId: 0
      },
      multipleSelectionAll: [],
      checkedIds: [],
      //选择的id集合
      cdkeyInfo: null,
      //卡密详情
      cardUrl: _settings.default.apiBaseURL + 'admin/merchant/card/secret/import/excel',
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      } // 上传参数
    };
  },
  mounted: function mounted() {
    this.tableFrom.libraryId = Number(this.$route.params.id);
    if (!localStorage.getItem('mediaDomain')) this.getMediadomain();
    if ((0, _permission.checkPermi)(['merchant:card:secret:page:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //判断勾选
    selectable: function selectable(row) {
      if (row.isUse) {
        return false;
      } else {
        return true;
      }
    },
    //图片域名
    getMediadomain: function getMediadomain() {
      var _this = this;
      (0, _systemSetting.mediadomainApi)().then(function (res) {
        localStorage.setItem('mediaDomain', res);
        _this.$store.commit('settings/SET_mediaDomain', res);
      });
    },
    //导入卡密前校验
    beforeUpload: function beforeUpload(file) {
      return (0, _ZBKJIutil.isFileUpload)(file);
    },
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this2 = this;
      var formData = new FormData();
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _productCdkey.cardSecretImportExcelApi)(formData, {
        libraryId: this.tableFrom.libraryId
      }).then(function (res) {
        loading.close();
        _this2.getList(1);
        _this2.$message.success('导入成功');
      }).catch(function (res) {
        loading.close();
      });
    },
    //下载模板
    handleDownload: function handleDownload() {
      window.open("".concat(this.mediaDomain, "/crmebimage/template/cdkey_template.xlsx"));
    },
    //批量删除
    handleBatchDel: function handleBatchDel() {
      var _this3 = this;
      if (this.checkedIds.length === 0) return this.$message.warning('请至少选择一项卡密');
      this.$modalSure('确定要将选中卡密删除吗？').then(function () {
        (0, _productCdkey.cardSecretBatchDeleteApi)({
          ids: _this3.checkedIds.join(',')
        }).then(function () {
          _this3.$message.success('批量删除成功');
          if (_this3.tableData.data.length === 1 && _this3.tableFrom.page > 1) _this3.tableFrom.page = _this3.tableFrom.page - 1;
          _this3.getList('');
        });
      });
    },
    // 设置选中的方法
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelectionAll = val;
      var data = [];
      this.multipleSelectionAll.map(function (item) {
        data.push(item.id);
      });
      this.checkedIds = data;
    },
    selectAll: function selectAll(data) {
      var id = data.map(function (i, index) {
        return i.id;
      });
      this.checkedIds = Array.from(new Set([].concat(_toConsumableArray(this.checkedIds), _toConsumableArray(id))));
    },
    selectOne: function selectOne(data, row) {
      var id = data.map(function (i, index) {
        return i.id;
      });
      var index = this.checkedIds.findIndex(function (e) {
        return e == row.id;
      });
      this.checkedIds.splice(index, 1);
      this.checkedIds = Array.from(new Set([].concat(_toConsumableArray(this.checkedIds), _toConsumableArray(id))));
    },
    //确认提交卡密
    handlerSubSuccess: function handlerSubSuccess(e) {
      this.$refs.addCarMy.cdkeyShow = false;
      this.getList(1);
    },
    closeCarMy: function closeCarMy() {
      this.$refs.addCarMy.cdkeyShow = false;
    },
    //编辑卡密回调
    handlerEditSubSuccess: function handlerEditSubSuccess() {
      this.getList(1);
    },
    //添加卡密
    handleAdd: function handleAdd() {
      this.$refs.addCarMy.cdkeyShow = true;
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _productCdkey.cardSecretPageListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this5 = this;
      this.$modalSure('确定删除此卡密吗？').then(function () {
        (0, _productCdkey.cardSecretDeleteApi)(id).then(function () {
          _this5.$message.success('删除成功');
          if (_this5.tableData.data.length === 1 && _this5.tableFrom.page > 1) _this5.tableFrom.page = _this5.tableFrom.page - 1;
          _this5.getList('');
        });
      });
    },
    //编辑
    handleEdit: function handleEdit(row) {
      this.$refs.editCarMy.cdkeyShow = true;
      this.cdkeyInfo = {
        cardNumber: row.cardNumber,
        secretNum: row.secretNum,
        key: Date.now(),
        id: row.id
      };
    }
  }
};