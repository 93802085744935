"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _breadcrumb = _interopRequireDefault(require("@/layout/navBars/breadcrumb/breadcrumb.vue"));
var _user = _interopRequireDefault(require("@/layout/navBars/breadcrumb/user.vue"));
var _index = _interopRequireDefault(require("@/layout/logo/index.vue"));
var _horizontal = _interopRequireDefault(require("@/layout/navMenu/horizontal.vue"));
var _transverseAside = _interopRequireDefault(require("@/layout/component/transverseAside.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutNavBars',
  components: {
    Breadcrumb: _breadcrumb.default,
    User: _user.default,
    Logo: _index.default,
    Horizontal: _horizontal.default,
    transverseAside: _transverseAside.default
  },
  data: function data() {
    return {
      menuList: []
    };
  },
  computed: {
    // 设置 logo 是否显示
    setIsShowLogo: function setIsShowLogo() {
      var _this$$store$state$th = this.$store.state.themeConfig.themeConfig,
        isShowLogo = _this$$store$state$th.isShowLogo,
        layout = _this$$store$state$th.layout;
      return isShowLogo && layout === 'classic' || isShowLogo && layout === 'transverse';
    },
    // 设置是否显示横向菜单
    isLayoutTransverse: function isLayoutTransverse() {
      var _this$$store$state$th2 = this.$store.state.themeConfig.themeConfig,
        layout = _this$$store$state$th2.layout,
        isClassicSplitMenu = _this$$store$state$th2.isClassicSplitMenu;
      return layout === 'transverse' || isClassicSplitMenu && layout === 'classic';
    },
    isLayoutClassic: function isLayoutClassic() {
      var layout = this.$store.state.themeConfig.themeConfig.layout;
      return layout === 'classic';
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.setFilterRoutes();
    this.bus.$on('routesListChange', function () {
      _this.setFilterRoutes();
    });
  },
  beforeDestroy: function beforeDestroy() {
    this.bus.$off('routesListChange');
  },
  methods: {
    // 设置路由的过滤
    setFilterRoutes: function setFilterRoutes() {
      this.menuList = this.filterRoutesFun(this.$store.state.user.menuList);
    },
    // 设置路由的过滤递归函数
    filterRoutesFun: function filterRoutesFun(arr) {
      var _this2 = this;
      return arr.filter(function (item) {
        return item.path;
      }).map(function (item) {
        item = Object.assign({}, item);
        if (item.children.length) item.children = _this2.filterRoutesFun(item.children);
        return item;
      });
    }
  },
  watch: {
    // 监听 vuex 数据变化
    '$store.state': {
      handler: function handler(val) {
        if (val.user.menuListlength === this.menuList.length) return false;
        this.setFilterRoutes();
      },
      deep: true
    }
  }
};