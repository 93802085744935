"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _aside = _interopRequireDefault(require("@/layout/component/aside.vue"));
var _header = _interopRequireDefault(require("@/layout/component/header.vue"));
var _main = _interopRequireDefault(require("@/layout/component/main.vue"));
var _columnsAside = _interopRequireDefault(require("@/layout/component/columnsAside.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutColumns',
  components: {
    Asides: _aside.default,
    Headers: _header.default,
    Mains: _main.default,
    ColumnsAside: _columnsAside.default
  },
  computed: {
    // 是否开启固定 header
    isFixedHeader: function isFixedHeader() {
      return this.$store.state.themeConfig.themeConfig.isFixedHeader;
    }
  }
};