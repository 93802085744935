"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutLogo',
  data: function data() {
    return {
      platMerLoginInfo: JSON.parse(_jsCookie.default.get('JavaMerInfo')) //登录后的信息
    };
  },
  computed: {
    // 获取布局配置信息
    getThemeConfig: function getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    },
    // 设置 logo 是否显示
    setShowLogo: function setShowLogo() {
      var _this$$store$state$th = this.$store.state.themeConfig.themeConfig,
        isCollapse = _this$$store$state$th.isCollapse,
        layout = _this$$store$state$th.layout;
      return !isCollapse || layout === 'classic' || document.body.clientWidth < 1000;
    }
  },
  mounted: function mounted() {},
  methods: {
    // logo 点击实现菜单展开/收起
    onThemeConfigChange: function onThemeConfigChange() {
      // if (
      //   this.$store.state.themeConfig.themeConfig.layout == 'columns' &&
      //   !this.$store.state.user.childMenuList.length &&
      //   this.$store.state.themeConfig.themeConfig.isCollapse
      // )
      //   return;
      // if (this.$store.state.themeConfig.themeConfig.layout === 'transverse' || this.$store.state.themeConfig.themeConfig.layout === 'classic') return false;
      // this.$store.state.themeConfig.themeConfig.isCollapse = !this.$store.state.themeConfig.themeConfig.isCollapse;
      this.$router.push("/dashboard");
    }
  }
};