var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["merchant:mp:live:goods:list"],
              expression: "['merchant:mp:live:goods:list']",
            },
          ],
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    inline: "",
                    "label-position": "right",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品搜索：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder:
                            "请输入直播商品名称/ID,商户名称,微信直播间id,微信审核单id",
                          clearable: "",
                          size: "small",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.keywords,
                          callback: function ($$v) {
                            _vm.keywords = $$v
                          },
                          expression: "keywords",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "list-tabs mb5",
              on: {
                "tab-click": function ($event) {
                  return _vm.getList(1)
                },
              },
              model: {
                value: _vm.tableForm.reviewStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.tableForm, "reviewStatus", $$v)
                },
                expression: "tableForm.reviewStatus",
              },
            },
            [
              _c("el-tab-pane", { attrs: { name: " ", label: "全部" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { name: "0", label: "商户创建" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { name: "1", label: "平台待审核/商户重新提交审核" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { name: "2", label: "平台审核通过" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { name: "3", label: "平台审核失败" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { name: "4", label: "微信审核成功" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { name: "5", label: "微信审核失败" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:mp:live:goods:save"],
                  expression: "['merchant:mp:live:goods:save']",
                },
              ],
              staticClass: "mb20",
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onEdit(0)
                },
              },
            },
            [_vm._v("添加直播商品")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", "min-width": "40" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品ID", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.productId))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品名称",
                  "min-width": "120",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.name + "/" + scope.row.productId)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview line-heightOne" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.coverImgUrlLocal,
                                "preview-src-list": [
                                  scope.row.coverImgUrlLocal,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "价格类型", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("priceTypeFilter")(scope.row.priceType)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "价格", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.priceType === 1
                          ? _c("span", [_vm._v(_vm._s(scope.row.price))])
                          : _c("span", [
                              _vm._v(
                                _vm._s(scope.row.price + "~" + scope.row.price2)
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", "min-width": "60", label: "排序" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reviewStatus === 3 ||
                        scope.row.reviewStatus === 5
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: scope.row.reviewReason,
                                  placement: "top",
                                },
                              },
                              [
                                scope.row.reviewStatus === 3
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass:
                                          "notStartTag tag-background",
                                      },
                                      [_vm._v("平台审核失败")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 5
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass:
                                          "notStartTag tag-background",
                                      },
                                      [_vm._v("微信审核失败")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                scope.row.reviewStatus === 1
                                  ? _c(
                                      "el-tag",
                                      {
                                        staticClass: "doingTag tag-background",
                                      },
                                      [_vm._v("平台待审核")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 2
                                  ? _c(
                                      "el-tag",
                                      { staticClass: "endTag tag-background" },
                                      [_vm._v("平台审核通过")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 4
                                  ? _c(
                                      "el-tag",
                                      { staticClass: "endTag tag-background" },
                                      [_vm._v("微信审核成功")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:mp:live:goods:sort"],
                                expression: "['merchant:mp:live:goods:sort']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleSort(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("排序")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:mp:live:goods:edit"],
                                expression: "['merchant:mp:live:goods:edit']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:mp:live:goods:delete"],
                                expression: "['merchant:mp:live:goods:delete']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": _vm.$constants.page.limit,
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", { ref: "ProDetail", on: { getList: _vm.getList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }