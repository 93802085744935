var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: "0 20px 20px" },
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "list-tabs",
              model: {
                value: _vm.loginType,
                callback: function ($$v) {
                  _vm.loginType = $$v
                },
                expression: "loginType",
              },
            },
            _vm._l(_vm.headeNum, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.name, name: item.type.toString() },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.loginType === "1"
            ? _c("div", { staticClass: "information" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "basic-information",
                  },
                  [
                    _c("div", [
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("商户名称："),
                      ]),
                      _vm._v(_vm._s(_vm.merData.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("商户登录帐号："),
                      ]),
                      _vm._v(_vm._s(_vm.merData.phone)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("商户负责人姓名："),
                      ]),
                      _vm._v(_vm._s(_vm.merData.realName)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("商户分类："),
                      ]),
                      _vm._v(_vm._s(_vm.merData.merCategory)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("商户类别："),
                      ]),
                      _vm._v(
                        _vm._s(_vm._f("selfTypeFilter")(_vm.merData.isSelf))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("店铺类型："),
                      ]),
                      _vm._v(_vm._s(_vm.merData.merType)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("商户手续费："),
                      ]),
                      _vm._v(_vm._s(_vm.merData.handlingFee) + "%"),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("添加商品："),
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.merData.productSwitch
                            ? "需平台审核"
                            : "平台免审核"
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "tips" }, [
                          _vm._v("商户星级："),
                        ]),
                        _c("el-rate", {
                          attrs: { disabled: "", "text-color": "#ff9900" },
                          model: {
                            value: _vm.merData.starLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "starLevel", $$v)
                            },
                            expression: "merData.starLevel",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("商户入驻时间："),
                      ]),
                      _vm._v(_vm._s(_vm.merData.createTime)),
                    ]),
                    _vm._v(" "),
                    _vm.merData.qualificationPicture
                      ? _c("div", [
                          _c("span", { staticClass: "tips" }, [
                            _vm._v("商户资质："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            _vm._l(
                              JSON.parse(_vm.merData.qualificationPicture),
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "pictrue" },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: item,
                                        "preview-src-list": [item],
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["merchant:switch:update"],
                            expression: "['merchant:switch:update']",
                          },
                        ],
                      },
                      [
                        _c("span", { staticClass: "tips" }, [
                          _vm._v("开启商户："),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-value": true,
                            "inactive-value": false,
                            "active-text": "开启",
                            "inactive-text": "关闭",
                          },
                          on: { change: _vm.changeSwitch },
                          model: {
                            value: _vm.merData.isSwitch,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "isSwitch", $$v)
                            },
                            expression: "merData.isSwitch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.loginType === "2"
            ? _c("div", { staticClass: "business-msg" }, [
                _c(
                  "div",
                  { staticClass: "form-data" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "merInfoForm",
                        staticClass: "demo-ruleForm",
                        attrs: {
                          model: _vm.merInfoForm,
                          rules: _vm.rules,
                          "label-width": "140px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商户主头像：", prop: "avatar" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox acea-row",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap("1", "avatar")
                                  },
                                },
                              },
                              [
                                _vm.merInfoForm.avatar
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: { src: _vm.merInfoForm.avatar },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "from-tips" }, [
                                  _vm._v("请上传小于500kb的图片（90*90 px）"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "H5商户背景图：",
                              prop: "backImage",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox acea-row",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap("1", "backImage")
                                  },
                                },
                              },
                              [
                                _vm.merInfoForm.backImage
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.merInfoForm.backImage,
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "from-tips" }, [
                                  _vm._v("请上传小于500kb的图片（375*180 px）"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "H5商户街背景图：",
                              prop: "streetBackImage",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox acea-row",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap(
                                      "1",
                                      "streetBackImage"
                                    )
                                  },
                                },
                              },
                              [
                                _vm.merInfoForm.streetBackImage
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.merInfoForm.streetBackImage,
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "from-tips" }, [
                                  _vm._v("请上传小于500kb的图片（355*78 px）"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "H5商户封面图：",
                              prop: "coverImage",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox acea-row",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap("1", "coverImage")
                                  },
                                },
                              },
                              [
                                _vm.merInfoForm.coverImage
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.merInfoForm.coverImage,
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "from-tips" }, [
                                  _vm._v("请上传小于500kb的图片（350*350 px）"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "H5商户logo（横）：",
                              prop: "rectangleLogo",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox acea-row",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap("1", "rectangleLogo")
                                  },
                                },
                              },
                              [
                                _vm.merInfoForm.rectangleLogo
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.merInfoForm.rectangleLogo,
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "from-tips" }, [
                                  _vm._v("请上传小于500kb的图片（300*88 px）"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商户简介：", prop: "intro" } },
                          [
                            _c("el-input", {
                              staticClass: "width100",
                              attrs: { type: "textarea", maxlength: "200" },
                              model: {
                                value: _vm.merInfoForm.intro,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.merInfoForm,
                                    "intro",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "merInfoForm.intro",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "商户关键字：", prop: "labelarr" },
                          },
                          [
                            _c("keyword", {
                              staticClass: "width100",
                              attrs: { labelarr: _vm.labelarr },
                              on: { getLabelarr: _vm.getLabelarr },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "客服类型：", prop: "serviceType" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "width100",
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.merInfoForm.serviceType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.merInfoForm,
                                      "serviceType",
                                      $$v
                                    )
                                  },
                                  expression: "merInfoForm.serviceType",
                                },
                              },
                              _vm._l(_vm.serviceList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.merInfoForm.serviceType === "H5"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "H5链接：",
                                  prop: "serviceLink",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "width100",
                                  model: {
                                    value: _vm.merInfoForm.serviceLink,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.merInfoForm,
                                        "serviceLink",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "merInfoForm.serviceLink",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.merInfoForm.serviceType === "phone"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "电话：",
                                  prop: "servicePhone",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "width100",
                                  model: {
                                    value: _vm.merInfoForm.servicePhone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.merInfoForm,
                                        "servicePhone",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "merInfoForm.servicePhone",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "警戒库存：", prop: "alertStock" },
                          },
                          [
                            _c("el-input-number", {
                              attrs: { min: 1, max: 10, label: "警戒库存" },
                              model: {
                                value: _vm.merInfoForm.alertStock,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.merInfoForm,
                                    "alertStock",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "merInfoForm.alertStock",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "自提开关：", prop: "alertStock" },
                          },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": true,
                                "inactive-value": false,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              model: {
                                value: _vm.merInfoForm.isTakeTheir,
                                callback: function ($$v) {
                                  _vm.$set(_vm.merInfoForm, "isTakeTheir", $$v)
                                },
                                expression: "merInfoForm.isTakeTheir",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "小票打印开关：",
                              prop: "receiptPrintingSwitch",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.merInfoForm.receiptPrintingSwitch,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.merInfoForm,
                                      "receiptPrintingSwitch",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "merInfoForm.receiptPrintingSwitch",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("关闭"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("手动打印"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("自动打印"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 3 } }, [
                                  _vm._v("自动和手动"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "商户地址：",
                              prop: "addressDetail",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "width100",
                              attrs: {
                                "enter-button": "查找位置",
                                placeholder: "请查找位置",
                                readonly: "",
                              },
                              model: {
                                value: _vm.merInfoForm.addressDetail,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.merInfoForm,
                                    "addressDetail",
                                    $$v
                                  )
                                },
                                expression: "merInfoForm.addressDetail",
                              },
                            }),
                            _vm._v(" "),
                            _c("iframe", {
                              attrs: {
                                id: "mapPage",
                                width: "100%",
                                height: "500px",
                                frameborder: "0",
                                src: _vm.keyUrl,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:config:info:edit"])
                          ? _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerSubmit("merInfoForm")
                                      },
                                    },
                                  },
                                  [_vm._v("确定")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.loginType === "3"
            ? _c("div", { staticClass: "business-msg" }, [
                _c(
                  "div",
                  { staticClass: "form-data" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "settlementForm",
                        attrs: {
                          model: _vm.settlementForm,
                          "label-width": "100px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "结算类型：",
                              "label-width": "120px",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.settlementForm.settlementType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.settlementForm,
                                      "settlementType",
                                      $$v
                                    )
                                  },
                                  expression: "settlementForm.settlementType",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "bank" } }, [
                                  _vm._v("银行卡"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: "wechat" } }, [
                                  _vm._v("微信"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: "alipay" } }, [
                                  _vm._v("支付宝"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.loginType === "3"
                      ? _c("z-b-parser", {
                          attrs: {
                            "is-create": 1,
                            "form-conf": _vm.formConf,
                            "edit-data": _vm.transferData,
                            "form-name": _vm.formId,
                            "key-num": _vm.keyNum,
                          },
                          on: { submit: _vm.transferhandlerSubmit },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }