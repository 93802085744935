var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _vm.checkPermi(["merchant:cdkey:library:page:list"])
        ? _c(
            "el-card",
            {
              staticClass: "ivu-mt",
              attrs: {
                bordered: false,
                shadow: "never",
                "body-style": { padding: 0 },
              },
            },
            [
              _c(
                "div",
                { staticClass: "padding-add" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "卡密名称：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入卡密库名称",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入商品名称",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.productName,
                              callback: function ($$v) {
                                _vm.productName =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "productName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:cdkey:library:add"],
                  expression: "['merchant:cdkey:library:add']",
                },
              ],
              staticClass: "mb20",
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v("添加卡密库")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "卡密库名称",
                  prop: "name",
                  "min-width": "200",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "关联商品",
                  "min-width": "150",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.productName))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "关联商品规格",
                  prop: "productAttrValueName",
                  "min-width": "200",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterEmpty")(
                                scope.row.productAttrValueName
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "未使用/卡密总数",
                  "min-width": "150",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              Number(scope.row.totalNum) -
                                Number(scope.row.usedNum) +
                                " / " +
                                scope.row.totalNum
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "备注",
                  prop: "remark",
                  "min-width": "250",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.remark))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:cdkey:library:update"],
                                expression: "['merchant:cdkey:library:update']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleAdd(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  "/product/cdkey/creatCdkey/" +
                                  scope.row.id +
                                  "/" +
                                  scope.row.name,
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["merchant:card:secret:page:list"],
                                    expression:
                                      "['merchant:card:secret:page:list']",
                                  },
                                ],
                                attrs: { type: "text" },
                              },
                              [_vm._v("管理卡密")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:cdkey:library:delete"]) &&
                        !scope.row.productAttrValueName &&
                        !scope.row.productName
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": _vm.$constants.page.limit,
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.formValidate.id === 0 ? "新增卡密库" : "编辑卡密库",
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "85px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "卡密库名称:", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入卡密库名称，最多32字",
                      maxlength: "32",
                      size: "small",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formValidate.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formValidate,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formValidate.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注:" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入备注，最多可输入200字",
                      maxlength: "200",
                      type: "textarea",
                      size: "small",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formValidate.remark,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formValidate,
                          "remark",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formValidate.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.checkPermi(["merchant:product:update"])
                ? _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary", loading: _vm.loadingBtn },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }