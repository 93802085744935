"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/utils/system.js");
var _index = _interopRequireDefault(require("@/layout/logo/index.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutColumnsAside',
  components: {
    Logo: _index.default
  },
  data: function data() {
    return {
      columnsAsideList: [],
      liIndex: 0,
      difference: 0,
      routeSplit: [],
      activePath: ''
    };
  },
  computed: {
    // 设置分栏高亮风格
    setColumnsAsideStyle: function setColumnsAsideStyle() {
      return this.$store.state.themeConfig.themeConfig.columnsAsideStyle;
    },
    // 设置分栏布局风格
    setColumnsAsidelayout: function setColumnsAsidelayout() {
      return this.$store.state.themeConfig.themeConfig.columnsAsideLayout;
    },
    Layout: function Layout() {
      return this.$store.state.themeConfig.themeConfig.Layout;
    },
    routesList: function routesList() {
      this.$store.state.user.menuList;
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.bus.$off('routesListChange');
  },
  mounted: function mounted() {
    var _this = this;
    this.bus.$on('routesListChange', function () {
      _this.setFilterRoutes();
    });
    this.setFilterRoutes();
    this.$nextTick(function (e) {
      _this.initElMenuOffsetLeft();
    });
  },
  methods: {
    // 设置横向滚动条可以鼠标滚轮滚动
    onElMenuHorizontalScroll: function onElMenuHorizontalScroll(e) {
      var eventDelta = e.wheelDelta || -e.deltaY * 40;
      this.$refs.elMenuHorizontalScrollRef.$refs.wrap.scrollLeft = this.$refs.elMenuHorizontalScrollRef.$refs.wrap.scrollLeft + eventDelta / 4;
    },
    // 初始化数据，页面刷新时，滚动条滚动到对应位置
    initElMenuOffsetLeft: function initElMenuOffsetLeft() {
      var _this2 = this;
      this.$nextTick(function () {
        var els = document.querySelector('.layout-columns.layout-columns-active');
        if (!els) return false;
        _this2.$refs.elMenuHorizontalScrollRef.$refs.wrap.scrollLeft = els.offsetLeft;
      });
    },
    // 设置菜单高亮位置移动
    setColumnsAsideMove: function setColumnsAsideMove(k) {
      if (k === undefined) return false;
      var els = this.$refs.columnsAsideOffsetLeftRefs;
      this.liIndex = k;
      this.$refs.columnsAsideActiveRef.style.left = "".concat(els[k].offsetLeft + this.difference, "px");
    },
    // 菜单高亮点击事件
    onColumnsAsideMenuClick: function onColumnsAsideMenuClick(v) {
      var path = v.path,
        redirect = v.redirect;
      if (v.children.length) {
        this.$router.push((0, _system.findFirstNonNullChildren)(v.children).path);
      } else {
        this.$router.push(path);
      }
      // 一个路由设置自动收起菜单
      if (!v.children || v.children.length <= 1) this.$store.state.themeConfig.themeConfig.isCollapse = true;else if (v.children.length > 1) this.$store.state.themeConfig.themeConfig.isCollapse = false;
    },
    // 设置高亮动态位置
    onColumnsAsideDown: function onColumnsAsideDown(k) {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.setColumnsAsideMove(k);
      });
    },
    // 设置/过滤路由（非静态路由/是否显示在菜单中）
    setFilterRoutes: function setFilterRoutes() {
      if (this.$store.state.user.menuList.length <= 0) return false;
      this.columnsAsideList = this.filterRoutesFun(this.$store.state.user.menuList);
      var resData = this.setSendChildren((0, _system.getHeaderName)(this.$route, this.columnsAsideList));
      if (!resData.item[0].children.length) {
        this.bus.$emit('setSendColumnsChildren', []);
        this.$store.commit('user/childMenuList', []);
        this.$store.state.themeConfig.themeConfig.isCollapse = true;
        return false;
      }
      this.bus.$emit('oneCatName', resData.item[0].title);
      this.onColumnsAsideDown(resData.item[0].k);
      // 刷新时，初始化一个路由设置自动收起菜单
      resData.item[0].children.length > 0 ? this.$store.state.themeConfig.themeConfig.isCollapse = false : this.$store.state.themeConfig.themeConfig.isCollapse = true;
      this.bus.$emit('setSendColumnsChildren', resData.item[0].children || []);
      this.$store.commit('user/childMenuList', resData.item[0].children || []);
    },
    // 传送当前子级数据到菜单中
    setSendChildren: function setSendChildren(path) {
      var currentData = {};
      this.columnsAsideList.map(function (v, k) {
        v['k'] = k;
        if (v.path === path) {
          currentData['item'] = [_objectSpread({}, v)];
          if (v.children.length) currentData['children'] = v.children;
        }
      });
      return currentData;
    },
    // 路由过滤递归函数
    filterRoutesFun: function filterRoutesFun(arr) {
      var _this4 = this;
      return arr.filter(function (item) {
        return item.path;
      }).map(function (item) {
        item = Object.assign({}, item);
        if (item.children.length) item.children = _this4.filterRoutesFun(item.children);
        return item;
      });
    },
    // tagsView 点击时，根据路由查找下标 columnsAsideList，实现左侧菜单高亮
    setColumnsMenuHighlight: function setColumnsMenuHighlight(path) {
      var _this5 = this;
      var currentSplitRoute = this.columnsAsideList.find(function (v) {
        return v.path === path;
      });
      if (!currentSplitRoute) {
        this.onColumnsAsideDown(0);
        return false;
      }
      // 延迟拿值，防止取不到
      setTimeout(function () {
        _this5.onColumnsAsideDown(currentSplitRoute.k);
      }, 0);
    }
  },
  watch: {
    // 监听 vuex 数据变化
    '$store.state': {
      handler: function handler(val) {
        val.themeConfig.themeConfig.columnsAsideStyle === 'columnsRound' ? this.difference = 3 : this.difference = 0;
        if (val.user.menuListlength === this.columnsAsideList.length) return false;
      },
      deep: true
    },
    // 监听路由的变化
    $route: {
      handler: function handler(to) {
        this.setColumnsMenuHighlight(to.path);
        var HeadName = (0, _system.getHeaderName)(to, this.columnsAsideList);
        var asideList = (0, _system.getMenuSider)(this.columnsAsideList, HeadName)[0].children;
        var resData = this.setSendChildren(HeadName);
        if (resData.length <= 0) return false;
        this.onColumnsAsideDown(resData.item[0].k);
        this.bus.$emit('oneCatName', resData.item[0].title);
        this.bus.$emit('setSendColumnsChildren', asideList || []);
        this.$store.commit('user/childMenuList', asideList || []);
      },
      deep: true
    }
  }
};