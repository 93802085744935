"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _permission = require("@/utils/permission");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var $constants = _interopRequireWildcard(require("@/utils/constants"));
var _public = require("@/libs/public");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 权限判断函数

var tableForms = {
  page: 1,
  limit: $constants.page.limit[0],
  name: '',
  date: ''
};
var _default = exports.default = {
  name: 'SeckillList',
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: Object.assign({}, tableForms),
      name: '',
      fromList: this.$constants.fromList,
      merStarLevel: _jsCookie.default.get('merStarLevel')
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:seckill:activity:page'])) this.getList(1);
    if ((0, _permission.checkPermi)(['merchant:plat:product:category:cache:tree'])) this.$store.dispatch('product/getAdminProductClassify');
  },
  methods: {
    checkPermi: _permission.checkPermi,
    goOn: function goOn(id) {
      this.$router.push({
        path: "/marketing/seckill/creatActivity/".concat(id)
      });
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this = this;
      this.$modalSure("\u5220\u9664\u8BE5\u79D2\u6740\u6D3B\u52A8\u5417\uFF1F").then(function () {
        (0, _marketing.seckillAtivityDelApi)(id).then(function () {
          _this.$message.success('删除成功');
          (0, _public.handleDeleteTable)(_this.tableData.data.length, _this.tableForm);
          _this.getList();
        });
      });
    },
    handleReset: function handleReset() {
      this.tableForm = Object.assign({}, tableForms);
      this.name = '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.tableForm.page = num ? num : this.tableForm.page;
      this.tableForm.name = encodeURIComponent(this.name);
      this.listLoading = true;
      (0, _marketing.seckillActivityListApi)(this.tableForm).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    }
  }
};