var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["merchant:product:rule:page:list"],
              expression: "['merchant:product:rule:page:list']",
            },
          ],
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: "20px 20px 2px" },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: { inline: "", size: "small" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "规格名称：" } },
                [
                  _c("el-input", {
                    staticClass: "form_content_width",
                    attrs: { placeholder: "请输入规格名称", clearable: "" },
                    model: {
                      value: _vm.keywords,
                      callback: function ($$v) {
                        _vm.keywords =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "keywords",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleSearchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            shadow: "never",
            bordered: false,
            "body-style": { padding: "20px" },
          },
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:product:rule:save"],
                  expression: "['merchant:product:rule:save']",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.add },
            },
            [_vm._v("添加商品规格")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticClass: "mt20",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ruleName",
                  label: "规格名称",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品规格", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.ruleValue,
                        function (item, index) {
                          return _c("span", {
                            key: index,
                            staticClass: "mr10",
                            domProps: { textContent: _vm._s(item.value) },
                          })
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品属性", "min-width": "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.ruleValue,
                        function (item, index) {
                          return _c("div", {
                            key: index,
                            domProps: {
                              textContent: _vm._s(item.detail.join(",")),
                            },
                          })
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:product:rule:update"],
                                expression: "['merchant:product:rule:update']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:product:rule:delete"],
                                expression: "['merchant:product:rule:delete']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": _vm.$constants.page.limit,
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }