var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative mallConfiguration" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: {
            shadow: "never",
            bordered: false,
            "body-style": { padding: "40px 50px" },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-data" },
            [
              _c(
                "el-form",
                {
                  ref: "pcConfigForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.pcConfigForm,
                    rules: _vm.rules,
                    "label-width": "129px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "PC商城商户logo：", prop: "pcLogo" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox acea-row",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1", "pcLogo")
                            },
                          },
                        },
                        [
                          _vm.pcConfigForm.pcLogo
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.pcConfigForm.pcLogo },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "line-heightOne from-tips" },
                            [_vm._v("请上传小于500kb的图片（60*60 px）")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "品牌好店商户封面：",
                        prop: "pcGoodStoreCoverImage",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox acea-row",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(
                                "1",
                                "pcGoodStoreCoverImage"
                              )
                            },
                          },
                        },
                        [
                          _vm.pcConfigForm.pcGoodStoreCoverImage
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.pcConfigForm.pcGoodStoreCoverImage,
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "line-heightOne from-tips" },
                            [_vm._v("请上传小于500kb的图片（228*228 px）")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺banner：" } },
                    [
                      _c("FromList", {
                        attrs: { configObj: _vm.bannerListConfig },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "店铺推荐商品：" } }, [
                    _c("div", { staticClass: "goods-box borderPadding" }, [
                      _c("div", { staticClass: "wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "dragArea list-group upLoadPicBox" },
                          [
                            _vm._l(_vm.goodsList, function (good, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item" },
                                [
                                  _c("img", {
                                    attrs: { src: good.image, alt: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-error",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleDelete(index)
                                      },
                                    },
                                  }),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.goodsList.length < 20
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "upLoad mb14",
                                    on: { click: _vm.handleAddGoods },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "from-tips",
                        staticStyle: { "margin-top": "0" },
                      },
                      [_vm._v("最多可选择20个商品")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:config:pc:shopping:save"],
                          expression: "['merchant:config:pc:shopping:save']",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "debounceClick",
                              rawName: "v-debounceClick",
                              value: function () {
                                _vm.handlePcConfigSave("pcConfigForm")
                              },
                              expression:
                                "\n              () => {\n                handlePcConfigSave('pcConfigForm');\n              }\n            ",
                            },
                          ],
                          attrs: { type: "primary" },
                        },
                        [_vm._v(_vm._s(_vm.loadingBtn ? "提交中 ..." : "保存"))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }