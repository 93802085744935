var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "container_box" },
        [
          _c("pages-header", {
            ref: "pageHeader",
            attrs: {
              title: "添加直播间",
              backUrl: "/marketing/broadcast/room",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "box-card mt14",
          attrs: { shadow: "never", bordered: false },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "直播间名字", prop: "roomName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.isDetail,
                      placeholder: "最短3个汉字，最长17个汉字",
                    },
                    model: {
                      value: _vm.formData.roomName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "roomName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.roomName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "主播昵称", prop: "anchorName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.isDetail,
                      placeholder: "最短2个汉字，最长15个汉字",
                    },
                    model: {
                      value: _vm.formData.anchorName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "anchorName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.anchorName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "主播微信号", prop: "anchorWechat" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isDetail },
                    model: {
                      value: _vm.formData.anchorWechat,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "anchorWechat",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.anchorWechat",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "主播副号微信号" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isDetail },
                    model: {
                      value: _vm.formData.subAnchorWechat,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "subAnchorWechat",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.subAnchorWechat",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "创建者微信号" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isDetail },
                    model: {
                      value: _vm.formData.createrWechat,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "createrWechat",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.createrWechat",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "背景图：", prop: "coverImgLocal" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox acea-row",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "coverImgLocal")
                        },
                      },
                    },
                    [
                      _vm.formData.coverImgLocal
                        ? _c(
                            "div",
                            { staticClass: "pictrue" },
                            [
                              _vm.formData.coverImgLocal && !_vm.isDetail
                                ? _c("img", {
                                    attrs: { src: _vm.formData.coverImgLocal },
                                  })
                                : _c("el-image", {
                                    staticStyle: {
                                      width: "60px",
                                      height: "60px",
                                    },
                                    attrs: {
                                      src: _vm.formData.coverImgLocal,
                                      "preview-src-list": [
                                        _vm.formData.coverImgLocal,
                                      ],
                                    },
                                  }),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "from-tips" }, [
                        _vm._v("建议像素1080*1920，大小不超过2M"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分享图：", prop: "shareImgLocal" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox acea-row",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "shareImgLocal")
                        },
                      },
                    },
                    [
                      _vm.formData.shareImgLocal
                        ? _c(
                            "div",
                            { staticClass: "pictrue" },
                            [
                              _vm.formData.shareImgLocal && !_vm.isDetail
                                ? _c("img", {
                                    attrs: { src: _vm.formData.shareImgLocal },
                                  })
                                : _c("el-image", {
                                    staticStyle: {
                                      width: "60px",
                                      height: "60px",
                                    },
                                    attrs: {
                                      src: _vm.formData.shareImgLocal,
                                      "preview-src-list": [
                                        _vm.formData.shareImgLocal,
                                      ],
                                    },
                                  }),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "from-tips" }, [
                        _vm._v("建议像素800*640，大小不超过1M"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播频道封面图：", prop: "feedsImgLocal" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox acea-row",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "feedsImgLocal")
                        },
                      },
                    },
                    [
                      _vm.formData.feedsImgLocal
                        ? _c(
                            "div",
                            { staticClass: "pictrue" },
                            [
                              _vm.formData.feedsImgLocal && !_vm.isDetail
                                ? _c("img", {
                                    attrs: { src: _vm.formData.feedsImgLocal },
                                  })
                                : _c("el-image", {
                                    staticStyle: {
                                      width: "60px",
                                      height: "60px",
                                    },
                                    attrs: {
                                      src: _vm.formData.feedsImgLocal,
                                      "preview-src-list": [
                                        _vm.formData.feedsImgLocal,
                                      ],
                                    },
                                  }),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "from-tips" }, [
                        _vm._v("建议像素800*800，大小不超过100KB"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播时间：", prop: "timeVal" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "550px" },
                    attrs: {
                      disabled: _vm.isDetail,
                      type: "datetimerange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00", "23:59:59"],
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOptions0,
                    },
                    on: {
                      change: _vm.onchangeTime,
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.formData.timeVal,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "timeVal", $$v)
                      },
                      expression: "formData.timeVal",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "from-tips" }, [
                    _vm._v(
                      "\n          开播时间需要在当前时间的10分钟后，并且开始时间不能在6个月之后。开播时间和结束时间间隔不得短于30分钟，不得超过24小时\n        "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播间类型：", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDetail },
                      model: {
                        value: _vm.formData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("推流")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("手机直播"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播间点赞：", prop: "closeLike" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDetail },
                      model: {
                        value: _vm.formData.closeLike,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "closeLike", $$v)
                        },
                        expression: "formData.closeLike",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "from-tips" }, [
                    _vm._v(
                      "若关闭，观众端将隐藏点赞按钮，直播开始后不允许开启"
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播间货架：", prop: "closeLike" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDetail },
                      model: {
                        value: _vm.formData.closeGoods,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "closeGoods", $$v)
                        },
                        expression: "formData.closeGoods",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "from-tips" }, [
                    _vm._v(
                      "若关闭，观众端将隐藏商品货架，直播开始后不允许开启"
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播间评论：", prop: "closeLike" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDetail },
                      model: {
                        value: _vm.formData.closeComment,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "closeComment", $$v)
                        },
                        expression: "formData.closeComment",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "from-tips" }, [
                    _vm._v(
                      "若关闭，观众端将隐藏评论入口，直播开始后不允许开启"
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播间收录：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDetail },
                      model: {
                        value: _vm.formData.isFeedsPublic,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "isFeedsPublic", $$v)
                        },
                        expression: "formData.isFeedsPublic",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "from-tips" }, [
                    _vm._v("默认开启收录"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播间回放：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDetail },
                      model: {
                        value: _vm.formData.closeReplay,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "closeReplay", $$v)
                        },
                        expression: "formData.closeReplay",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "from-tips" }, [
                    _vm._v("直播开始后允许开启"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播间分享：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDetail },
                      model: {
                        value: _vm.formData.closeShare,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "closeShare", $$v)
                        },
                        expression: "formData.closeShare",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "from-tips" }, [
                    _vm._v("直播开始后不允许修改"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播间客服：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDetail },
                      model: {
                        value: _vm.formData.closeKf,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "closeKf", $$v)
                        },
                        expression: "formData.closeKf",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "from-tips" }, [
                    _vm._v("直播开始后允许开启"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isDetail
                ? _c(
                    "el-form-item",
                    { attrs: { label: "已导入的直播商品：" } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "table",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableData.data,
                            size: "samll",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "id",
                              label: "ID",
                              "min-width": "50",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "商品图", "min-width": "100" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "demo-image__preview line-heightOne",
                                        },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              src: scope.row.coverImgUrlLocal,
                                              "preview-src-list": [
                                                scope.row.coverImgUrlLocal,
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              863973982
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "商品名称",
                              "min-width": "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "价格类型", "min-width": "80" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("priceTypeFilter")(
                                              scope.row.priceType
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              411771551
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "价格", "min-width": "100" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.priceType === 1
                                        ? _c("span", [
                                            _vm._v(_vm._s(scope.row.price)),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.price +
                                                  "~" +
                                                  scope.row.price2
                                              )
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3287871236
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:mp:live:room:deletegoods"],
                                expression:
                                  "['merchant:mp:live:room:deletegoods']",
                              },
                            ],
                            attrs: {
                              label: "操作",
                              width: "70",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2306549704
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isDetail
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: [
                                "merchant:mp:live:room:create",
                                "merchant:mp:live:room:edit",
                              ],
                              expression:
                                "['merchant:mp:live:room:create', 'merchant:mp:live:room:edit']",
                            },
                            {
                              name: "debounceClick",
                              rawName: "v-debounceClick",
                              value: function () {
                                _vm.submitForm("formData")
                              },
                              expression:
                                "\n            () => {\n              submitForm('formData');\n            }\n          ",
                            },
                          ],
                          attrs: { type: "primary" },
                        },
                        [_vm._v("立即提交")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }